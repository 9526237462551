import styled, { keyframes } from 'styled-components';
import { Text } from '@arcadiapower/shrike';

const animation = keyframes`
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
`;

export const LoadingText = styled(Text).attrs({
  margin: { left: '32px' },
  textStyle: 'paragraph400',
})`
  &:after {
    display: inline-block;
    vertical-align: bottom;
    animation: ${animation} steps(1, end) 1s infinite;
    content: '';
    min-width: 15px;
  }
`;
