import React from 'react';
import { Text } from '@arcadiapower/shrike';
import { AccountAvatar } from 'components/account-avatar';
import { sortByDateAscending } from 'domain/for-warbler';
import { Container } from 'styles';
import { useAccountContext } from 'contexts/account';
import { useSwitchAccount } from 'hooks/use-switch-account.hook';
import { copyFor } from 'config';
import { Accordion } from 'components/accordion';
import { getAccountLabel } from 'domain/account-statuses';
import {
  StyledButton,
  StyledText,
  AccountsWrapper,
} from './account-switcher.style';

const getCopy = copyFor('accountSwitcher');

interface Props {
  onClose?: () => void;
}

export const AccountSwitcher = ({ onClose }: Props) => {
  const { selectedAccount, accounts } = useAccountContext();
  const { switchAccount, showAccountSwitcher } = useSwitchAccount({ onClose });

  if (!showAccountSwitcher) return null;

  const selectableAccounts = accounts.filter(
    account => account.id !== selectedAccount.id
  );
  const sortedAccounts = sortByDateAscending(selectableAccounts);

  return (
    <Container margin={{ bottom: '24px' }}>
      <Container margin={{ left: '8px' }} flex alignItems="center">
        <AccountAvatar accountId={selectedAccount.id} />
        <Text
          className="notranslate"
          margin={{ left: '8px' }}
          textStyle="heading500"
        >
          {getAccountLabel(selectedAccount)}
        </Text>
      </Container>
      <Accordion
        buttonProps={{
          justifyContent: 'flex-start',
          padding: '8px',
          margin: { left: '32px' },
          fullWidth: false,
        }}
        contentPadding="0"
        label={
          <Text
            className="notranslate"
            color="secondary"
            textStyle="paragraph400"
          >
            {getCopy('dropdownLabel')}
          </Text>
        }
      >
        {({ expanded }) => (
          <AccountsWrapper flex direction="column">
            {sortedAccounts.map(account => {
              const accountLabel = getAccountLabel(account);
              return (
                <StyledButton
                  disabled={!expanded}
                  aria-label={getCopy('button', { accountLabel })}
                  key={`button-switch-${account.id}`}
                  onClick={() => switchAccount(account.id.toString())}
                >
                  <div>
                    <AccountAvatar accountId={account.id} />
                  </div>
                  <StyledText
                    className="notranslate"
                    textStyle="paragraph400"
                    margin={{ left: '6px' }}
                  >
                    {accountLabel}
                  </StyledText>
                </StyledButton>
              );
            })}
          </AccountsWrapper>
        )}
      </Accordion>
    </Container>
  );
};
