import React from 'react';
import { trackError } from 'domain/tracking';
import { ErrorPage } from 'components/errors';
import { Container } from 'styles';

interface Props {
  children: React.ReactNode;
  variant?: string;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    trackError({ error });
  }

  render() {
    if (this.state.hasError)
      return (
        <Container flex justifyContent="center">
          <ErrorPage variant={this.props.variant} />
        </Container>
      );
    return this.props.children;
  }
}
