import React from 'react';
import SVG from 'react-inlinesvg';
import { useAccountContext } from 'contexts/account';
import Avatar from '/images/icons/avatar.svg';
import { FILL_MAP, getFillIndex } from './account-avatar.utils';

interface Props {
  accountId: number;
  size?: string;
}

export const AccountAvatar = (props: Props) => {
  const { accounts } = useAccountContext();
  const accountIndex = accounts.findIndex(a => a.id === props.accountId);
  const fill = FILL_MAP[getFillIndex(accountIndex)];

  const size = props.size ? props.size : '24px';

  // key is needed to trigger preProcessor
  return (
    <SVG
      data-testid={fill}
      src={Avatar}
      height={size}
      width={size}
      aria-hidden={true}
      preProcessor={code => code.replace(/fill="#B7EDED"/g, `fill="${fill}"`)}
      key={props.accountId}
    ></SVG>
  );
};
