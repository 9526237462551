import { useState } from 'react';
import { identity } from 'lodash-es';

export const useAsync = (
  fn: (...args: any[]) => any,
  parseError = identity
) => {
  const [state, setState] = useState<{ error?: string; loading: boolean }>({
    error: '',
    loading: false,
  });

  const asyncFn = async (...params) => {
    setState({
      error: '',
      loading: true,
    });
    try {
      await fn(...params);
      setState({ error: undefined, loading: false });
    } catch (e) {
      setState({ error: parseError(e), loading: false });
    }
  };
  const { error, loading } = state;
  return [{ error, loading }, asyncFn] as const;
};
