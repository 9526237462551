import React, { useCallback, useEffect, useRef } from 'react';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import { useLocation } from 'react-router-dom';
import { Text } from '@arcadiapower/shrike';
import { copyFor, routes } from 'config';
import { Container } from 'styles';
import { logout } from 'utils/logout';
import { PlanSwitcher, Variant } from 'components/plan-switcher';
import { useAccountPlansCount } from 'hooks/use-account-plans-count.hook';
import { AccountSwitcher } from './account-switcher';
import { NavLink } from '../../nav-link';
import { NavItem } from '../../nav-item';
import { NotificationIconLink } from '../../notification-icon-link';
import {
  StyledModal,
  StyledButton,
  CloseButton,
  StyledIcon,
} from './side-nav.style';
import { LanguagePreferences } from '../../language-pref/index';

const getCopy = copyFor('navigation.sideNav');
const { dashboard, settings } = routes();

interface Props {
  onClose: () => void;
  openSupportModal: () => void;
}

export const SideNav = ({ onClose, openSupportModal }: Props) => {
  // need to get planId from the pathname, not params, because the SideNav renders outside dashboard routes
  const { pathname } = useLocation();
  const planId = pathname.split('/')[2];
  const trap = useRef<FocusTrap | null>(null);

  const { data, loading, error } = useAccountPlansCount();

  const modalRef = useCallback(
    node => {
      if (node !== null) {
        // This approach assumes our pattern of only mounting one modal at a time
        trap.current = createFocusTrap(node, {
          clickOutsideDeactivates: true,
          onDeactivate: onClose,
        });
        try {
          trap.current.activate();
        } catch (e) {
          // We don't need to do anything with the "error". Focus trap activation will happen after the modal data is loaded.
        }
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (trap.current) trap.current.deactivate();
  }, []);

  // hide mobile plan switcher for plan whales
  const getPlanSwitcher = useCallback(() => {
    return loading || error || data?.isPlanWhale ? null : (
      <PlanSwitcher
        variant={Variant.List}
        onClose={onClose}
        planId={Number(planId)}
      />
    );
  }, [data, loading, error, onClose, planId]);

  return (
    <StyledModal onBackdropClick={onClose}>
      <div ref={modalRef} id="side-nav">
        <CloseButton
          aria-label={getCopy('close')}
          onClick={onClose}
          margin={{ left: '16px' }}
        />
        <nav>
          <Container
            flex
            direction="column"
            alignItems="flex-start"
            margin={{ left: '16px', top: '64px' }}
          >
            <AccountSwitcher onClose={onClose} />
            <NavLink path={dashboard} onClick={onClose}>
              <StyledIcon icon="Home" />
              <Text textStyle="heading500" color="primary">
                {getCopy('dashboard')}
              </Text>
            </NavLink>
            {getPlanSwitcher()}
            <NavItem>
              <StyledIcon
                icon="Globe"
                style={{ marginTop: '3px', marginLeft: '8px' }}
              />
              <LanguagePreferences type="mobile" />
            </NavItem>
            <NotificationIconLink
              type="sideNav"
              iconColor="primary"
              onClose={onClose}
            />
            <NavLink path={settings} onClick={onClose}>
              <StyledIcon icon="UISettingsInverted" />
              <Text textStyle="heading500" color="primary">
                {getCopy('settings')}
              </Text>
            </NavLink>
            <StyledButton
              onClick={() => {
                openSupportModal();
                onClose();
              }}
            >
              <StyledIcon icon="QuestionCircle" />
              {getCopy('help')}
            </StyledButton>
            <StyledButton onClick={() => logout()}>
              <StyledIcon icon="SignOut" />
              {getCopy('signout')}
            </StyledButton>
          </Container>
        </nav>
      </div>
    </StyledModal>
  );
};
