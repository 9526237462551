import { trackError } from '/src/domain/tracking';
/* eslint-disable */

// Taken from
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/

const initSegment = () => {
  const key = import.meta.env.ARCADIA_SEGMENT_WRITE_KEY;
  const analytics = (window.analytics = window.analytics || []);
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;
  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }
  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ];
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };
  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }
  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create an async script element based on your key.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
    // Insert our script in the head. Should come after OneTrust.
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
    analytics._loadOptions = options;
  };
  analytics._writeKey = key;
  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.15.2';
  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account.
  //
  // We specify a key according to the persistance docs to prevent
  // subdomain contamination between our Community Solar and other apps, which
  // may have a different user model
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/persistence/
  analytics.load(key);
};
// Safely init segment, so that if anything weird happens,
// we do not crash the application
try {
  initSegment();
} catch (e) {
  trackError({ error: e, message: 'Error initializing segment' });
}
