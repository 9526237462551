import { isBefore, formatDate } from '@arcadiapower/warbler';
import { copyFor } from 'config';

type SortableObject = {
  createdAt: Date;
};

export const sortByDateAscending = <T extends SortableObject>(
  array: T[]
): T[] => array.sort((a, b) => (isBefore(a.createdAt, b.createdAt) ? -1 : 1));

const getCopy = copyFor('utils');
export const getSeason = (date: string): string => {
  const quarter = formatDate(date, { format: 'q' });

  switch (quarter) {
    case '1':
      return getCopy('seasons.winter');
    case '2':
      return getCopy('seasons.spring');
    case '3':
      return getCopy('seasons.summer');
    case '4':
      return getCopy('seasons.fall');
    default:
      return getCopy('seasons.winter');
  }
};
