import React from 'react';
import { StyledContainer } from './nav-item.style';

interface Props {
  children: React.ReactNode;
}

export const NavItem = (props: Props) => {
  const { children } = props;
  return <StyledContainer>{children}</StyledContainer>;
};
