import { sortByDate } from '@arcadiapower/warbler';
import { routes, copyFor } from 'config';
import { AccountContextAccount } from 'contexts/account/account.typings';

const ACTIVE = 'active';
const DELETED = 'deleted';

type AccountWithStatus = {
  status: string;
};

export const isActive = (status: string): boolean => status === ACTIVE;
export const isDeleted = (status: string): boolean => status === DELETED;
export const allAccountsDeleted = <T extends AccountWithStatus>(
  accounts: T[]
): boolean => accounts.every(account => isDeleted(account.status));

export const getActiveAccounts = <T extends AccountWithStatus>(
  accounts: T[]
): T[] => accounts.filter(account => isActive(account.status));

type SortableAccount = {
  createdAt: string;
  id: number;
  customPlans: SortablePlan[];
};

type SortablePlan = {
  id: number;
  utilityAccounts: {
    id: number;
    utility: {
      id: number;
    };
  }[];
};

const getUtilityIdFromAccount = <T extends SortableAccount>(
  account: T
): number | undefined => {
  const utilityAccount = account.customPlans[0].utilityAccounts?.[0];
  return utilityAccount?.utility?.id;
};

const sortAccountsByDate = <T extends SortableAccount>(accounts: T[]): T[] =>
  sortByDate(accounts, 'createdAt').reverse();

const getOldestAccountUtilityId = <T extends SortableAccount>(
  accounts: T[]
): number | undefined => {
  return getUtilityIdFromAccount(sortAccountsByDate(accounts)[0]);
};

// This function groups all accounts by utility, sorts within each utility from oldest to
// newest account createdAt date. The account with the oldest createdAt date is loosely
// considered a customer's main account. Accounts with that utility come first in the
// array. The rest of the utilities are arbitrarily sorted by utility id.
export const sortAccounts = <T extends SortableAccount>(accounts: T[]): T[] => {
  const oldestAccountsUtilityId = getOldestAccountUtilityId(accounts);
  return accounts.sort((a, b) => {
    const utilityIdA = getUtilityIdFromAccount(a) || 0;
    const utilityIdB = getUtilityIdFromAccount(b) || 0;
    if (utilityIdA !== utilityIdB) {
      // Accounts from the same utility as the oldest account should be first
      if (utilityIdA === oldestAccountsUtilityId) {
        return -1;
      } else if (utilityIdB === oldestAccountsUtilityId) {
        return 1;
        // If neither account is from the same as the oldest, group by utility
      } else {
        return utilityIdA - utilityIdB;
      }
    } else {
      // Sort by date within the same utility group
      return new Date(b.createdAt) < new Date(a.createdAt) ? 1 : -1;
    }
  });
};

const getCopy = copyFor('accountSwitcher');

interface RedirectProps {
  pathname: string;
}

export const getRedirectRoute = ({
  pathname,
}: RedirectProps): string | undefined => {
  const { settings, dashboard, notifications } = routes();

  const path = `/${pathname.split('/')[1]}`;

  switch (path) {
    case settings:
      return settings;
    case notifications:
      return undefined;
    default:
      return dashboard;
  }
};

export const getAccountLabel = (account: AccountContextAccount): string => {
  const { name, status } = account;
  const closedLabel = isDeleted(status) ? getCopy('closedLabel') : '';
  return `${name}${closedLabel}`;
};
