import gql from 'graphql-tag';

export const GET_ACCOUNT_UTILITY_INFO = gql`
  query getAccountUtilityInfo($accountId: Int!) {
    account(id: $accountId) {
      utilityCredentials {
        id
        createdAt
      }
    }
  }
`;
