import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Notification } from '@arcadiapower/shrike';
import { Item } from 'react-stately';
import { copyFor } from 'config';
import { useAccountContext } from 'contexts/account';
import { LoadingText } from 'styles';
import { NOTIFICATION_DISPLAY_TIME } from 'domain/ux';
import { Dropdown } from 'components/dropdown';
import { sortActiveAndClosedPlans, isInactive } from 'domain/plans';
import { PlanSwitcherPlan, GET_PLANS_QUERY } from './plan-switcher.api';
import { List } from './list';
import { getRedirectRoute } from './plan-switcher.utils';

export enum Variant {
  Select = 'select',
  List = 'list',
}

interface Props {
  variant?: Variant;
  onClose?: () => void;
  planId?: number;
}

const getNotificationCopy = copyFor('toastNotifications');
const getCopy = copyFor('planSwitcher');

export const getPlanLabel = (plan: PlanSwitcherPlan) => {
  const { displayName, status } = plan;
  const closedLabel = isInactive(status) ? getCopy('closedLabel') : '';
  return `${displayName}${closedLabel}`;
};

export const PlanSwitcher = ({
  variant = Variant.Select,
  onClose,
  planId,
}: Props) => {
  const history = useHistory();
  const { selectedAccountId } = useAccountContext();
  const { addNotification } = useContext(Notification.Context);
  const { data, loading, error } = useQuery<
    GetPlansQuery,
    GetPlansQueryVariables
  >(GET_PLANS_QUERY, {
    variables: { id: selectedAccountId },
  });

  if (loading)
    return (
      <LoadingText data-testid="plan-switcher-loading">
        {getCopy('loading')}
      </LoadingText>
    );

  const plans = data?.account.customPlans;
  if (error || !plans || plans.length === 1) return null;

  const sortedPlans = sortActiveAndClosedPlans(plans);

  const handleRedirect = selectedPlan => {
    const route = getRedirectRoute({
      currentPlanId: planId,
      selectedPlan,
      pathname: history.location.pathname,
    });
    history.push(route);
  };

  const onSelectionChange = (id: string) => {
    // We know that the selected account must be in the list of accounts
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const selectedPlan = plans.find(plan => plan.id.toString() === id)!;
    addNotification({
      displayTime: NOTIFICATION_DISPLAY_TIME,
      message: getNotificationCopy('accountSwitched', {
        name: getPlanLabel(selectedPlan),
      }),
      variant: 'success',
    });
    handleRedirect(selectedPlan);
    if (onClose) onClose();
  };

  if (variant === Variant.Select) {
    return (
      <Dropdown
        optionClassName="notranslate"
        label={getCopy('dropdownLabel')}
        items={sortedPlans}
        selectedKey={planId && planId.toString()}
        onSelectionChange={onSelectionChange}
      >
        {item => <Item key={item.id}>{getPlanLabel(item)}</Item>}
      </Dropdown>
    );
  } else {
    return (
      <List
        className="notranslate"
        selectedPlanId={planId}
        plans={sortedPlans}
        onClick={onSelectionChange}
      />
    );
  }
};
