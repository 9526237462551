export const advocacy = {
  explainer: {
    title: {
      nonDefault: 'What does this legislation mean?',
      default: 'Why community solar?',
    },
    paragraph2:
      ' That means a more resilient electric grid, fewer fossil fuels, and an even easier way to choose clean energy.',
  },
  message: {
    tip: '<b>Tip:</b> personalize your message to resonate with your representatives more!',
    title: 'Email your representatives',
    subjectLabel: 'Subject line',
    greeting: 'Dear %{representativeNames},',
    titles: {
      senator: 'Senator %{lastName}',
      representative: 'Representative %{lastName}',
      councilMember: 'Councilmember %{lastName}',
    },
    bodyLabel: 'Email body',
    signOff: 'Sincerely,',
    loadingSignature: '{Your full name}',
    button: 'Send emails',
    representatives: {
      title: 'Your representatives',
    },
    address: {
      title: 'Your address',
      link: 'Not your address?',
    },
  },
  address: {
    title: 'Find your representatives',
    addressInputLabel: 'Your address',
    button: 'Search',
  },
  success: {
    title: 'Success!',
    description:
      'We’ve sent your emails to your representatives. You can make an even bigger impact by telling your friends and family in %{state} to voice their support, too.',
    sharePrompt: {
      default:
        'Tell friends to support community solar in %{stateAbbreviation}:',
      nonDefault: 'Ask friends to support this bill:',
    },
    share: 'Share',
    tweet: 'Tweet',
    backToDashboard: 'Back to dashboard',
    imageAriaLabel: 'Solar farm',
    copyAriaLabel: 'Copy the above share text',
  },
};
