import gql from 'graphql-tag';
import store from 'store';
import { authKey } from 'contexts/session';
import { apolloClient } from 'apollo-client';

export const LINK_EXPIRED = 'LINK_EXPIRED';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const END_SESSION_REQUEST_NAME = 'EndSession';
export const END_SESSION = gql`
  mutation EndSession {
    endSession {
      success
    }
  }
`;
interface Params {
  message?: string;
  pathname?: string;
  operationName?: string;
}

export const logout = async ({
  message,
  pathname,
  operationName,
}: Params = {}): Promise<void> => {
  // do not logout if logout in progress and endSession was already attempted and failed (due to invalid token)
  if (operationName !== END_SESSION_REQUEST_NAME) {
    // invalidate all session JWT tokens if token exists
    if (store.get(authKey))
      await apolloClient.mutate({ mutation: END_SESSION }).catch(() => null); // catch and do nothing for already expired tokens
    await apolloClient.clearStore(); // clear cache
    apolloClient.stop(); // shut down apollo instance
    store.remove(authKey);
    let queryParams = '';
    if (message === LINK_EXPIRED) queryParams = '&expired=link';
    else if (message === SESSION_EXPIRED) queryParams = '&expired=session';
    if (pathname && pathname !== '/')
      queryParams = `${queryParams}&redirectPath=${pathname}`;
    window.location.href = `${process.env.ARCADIA_SPHINX_URL}?${queryParams}`;
    localStorage.removeItem('utilityModalClosed');
  }
};
