import React, { lazy, useState } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { routes as getRoutes, copyFor, config } from 'config';
import { AppContent } from 'styles';
import { MOBILE_NAV_HEIGHT, NAV_HEIGHT } from 'domain/style';
import { Navigation } from 'components/navigation';
import UtilityRedirectModal from 'components/utility-modal';
import { UtilityAccountScrape } from 'components/subscription/update-account-scrape/subscription';
import { LegacyDeepLinkRedirect } from 'components/legacy-deep-link-redirect';
import { useTrackPage } from 'hooks/use-track-page.hook';
import { mapPages } from 'utils/map-pages';
import { withSuspense } from 'utils/with-suspense';
import { shouldUsePrimaryBackgroundColor } from 'styles/utils';

const getCopy = copyFor('navigation.navBar.links');

const Dashboard = lazy(() => import('./dashboard'));
const Settings = lazy(() => import('./settings'));
const Notifications = lazy(() => import('./notifications'));
// TODO move advocacy directory
const Advocacy = lazy(() => import('pages/advocacy'));

const pageMap = {
  dashboard: () => withSuspense(Dashboard),
  notifications: () => withSuspense(Notifications),
  profile: () => withSuspense(Settings),
  settings: () => withSuspense(Settings),
  advocacy: () => withSuspense(Advocacy),
};
const routes = getRoutes();

const { breakpoints } = config.theme.media;

const getPages = () => ({
  dashboard: {
    display: true,
    route: routes.dashboard,
    title: getCopy('dashboard'),
  },
  notifications: {
    display: true,
    route: routes.notifications,
    title: getCopy('notifications'),
  },
  profile: {
    display: true,
    route: routes.profile,
    title: getCopy('settings'),
    // TODO: DASH-2250 Remove display data for Profile and Profile section event tracking when we are no longer sending any users directly to Profile
  },
  settings: {
    display: true,
    route: routes.settings,
    title: getCopy('settings'),
  },
  advocacy: {
    display: true,
    route: routes.advocacy,
    title: getCopy('advocacy'),
  },
});

export const Pages = () => {
  useTrackPage();
  const { width } = useWindowSize();
  const location = useLocation();
  const pages = getPages();
  const navOffset = width > breakpoints.small ? NAV_HEIGHT : MOBILE_NAV_HEIGHT;
  const [scraperFailed, setScraperFailed] = useState(false);

  // TO DO : Wrap the entire subscription flow using context provider
  // like done in osprey

  const handleOnData = successStatus => {
    if (successStatus != null && !successStatus) setScraperFailed(true);
  };
  const [scraperId, setScraperId] = useState<string>(
    localStorage.getItem('scraperId') || ''
  );
  const handleRemoveScraperId = () => {
    localStorage.removeItem('scraperId');
    setScraperId('');
  };

  return (
    <>
      <Navigation />
      <UtilityAccountScrape
        onDataCallback={handleOnData}
        scrapeId={scraperId}
      />
      <UtilityRedirectModal
        scraperfailed={scraperFailed}
        onRemoveScraperId={handleRemoveScraperId}
      />
      <AppContent
        usePrimaryBackgroundColor={shouldUsePrimaryBackgroundColor(location)}
        navOffset={navOffset}
      >
        <Switch>
          {mapPages({ pages, pageMap })}
          <Route
            path={routes.legacyBaseRoutes}
            component={LegacyDeepLinkRedirect}
          />
          <Redirect to={routes.dashboard} />
        </Switch>
      </AppContent>
    </>
  );
};
