import { routes } from 'config';
import {
  getDisplayBilling,
  getDisplayUsage,
} from 'hooks/use-page-display.hook';
import { PlanSwitcherPlan } from './plan-switcher.api';

interface PlanHomePageProps {
  selectedPlan: PlanSwitcherPlan;
  displayUsage: boolean;
}

export const getPlanHomePage = ({
  selectedPlan,
  displayUsage,
}: PlanHomePageProps): string => {
  const vnmAvailable = selectedPlan.utilityAccounts.reduce((acc, ua) => {
    if (ua.utility.vnmProductAvailable) {
      return true;
    } else return acc;
  }, false);
  const { solar, usage } = routes({ planId: selectedPlan.id });

  if (vnmAvailable || !displayUsage) {
    return solar;
  } else {
    return usage;
  }
};

interface RedirectProps {
  selectedPlan: PlanSwitcherPlan;
  pathname: string;
  currentPlanId?: number;
}

export const getRedirectRoute = ({
  selectedPlan,
  pathname,
  currentPlanId,
}: RedirectProps): string => {
  const { cleanKilowattHours, status, mostRecentStatement, utilityAccounts } =
    selectedPlan;
  const vnmEligible = utilityAccounts?.some(ua => ua.utility.vnmEligible);
  const hasMostRecentStatement = !!mostRecentStatement;
  const displayUsage = getDisplayUsage({ cleanKilowattHours });
  const displayBilling = getDisplayBilling({
    status,
    hasMostRecentStatement,
    vnmEligible,
  });
  const homePageForSelectedAccount = getPlanHomePage({
    selectedPlan,
    displayUsage,
  });
  const routesWithCurrentAccountId = routes({ planId: currentPlanId });
  const routesWithSelectedAccountId = routes({
    planId: selectedPlan.id,
  });

  switch (pathname) {
    case routesWithCurrentAccountId.solar:
      return routesWithSelectedAccountId.solar;
    case routesWithCurrentAccountId.billing:
      return displayBilling
        ? routesWithSelectedAccountId.billing
        : homePageForSelectedAccount;
    case routesWithCurrentAccountId.usage:
      return displayUsage
        ? routesWithSelectedAccountId.usage
        : homePageForSelectedAccount;
    default:
      return homePageForSelectedAccount;
  }
};
