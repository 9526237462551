import React, { useRef } from 'react';
import { useOption } from 'react-aria';
import { Node } from '@react-types/shared';
import { ListState } from 'react-stately';
import { Icon } from '@arcadiapower/shrike';
import { Container } from 'styles';
import { ListItem, Text } from '../dropdown.style';

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
  className?: string;
}

export const Option = ({ item, state, className }: OptionProps) => {
  const ref = useRef<HTMLLIElement>(null);
  // isFocused is true for both keyboard focus and mouse hover when using this component with the Combobox component
  const { optionProps, isSelected, isFocused } = useOption(
    { key: item.key },
    state,
    ref
  );

  return (
    <ListItem
      {...optionProps}
      isFocused={isFocused}
      ref={ref}
      className={className}
    >
      <Container flex alignItems="center">
        {isSelected && (
          <Icon
            data-testid="dropdown-option-icon"
            icon="Checkmark"
            margin={{ left: '-24px' }}
            scale={2}
          />
        )}
        <Text
          isSelected={isSelected}
          margin={isSelected ? { left: '8px' } : undefined}
        >
          {item.rendered}
        </Text>
      </Container>
    </ListItem>
  );
};
