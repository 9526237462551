import gql from 'graphql-tag';

export const userIdMock = 10;

export const accountNickname = 'My plan';
export const deletedAccountNickname = 'My deleted plan';

export const GET_FUNNEL_SOURCE = gql`
  query getFunnelSource($userId: Int!) {
    originationSource(userId: $userId) {
      funnel
      id
    }
  }
`;

export const getOriginationSourceMock = {
  originationSource: {
    funnel: 'waitlist',
    id: 5,
  },
};

const firstActiveAccount = {
  __typename: 'Account',
  activeAt: '2020-04-12T04:00:00.000Z',
  createdAt: '2020-04-12T04:00:00.000Z',
  customPlans: [
    {
      __typename: 'Plan',
      id: 20,
      name: accountNickname,
    },
  ],
  id: 1,
  status: 'active',
};

const secondActiveAccount = {
  __typename: 'Account',
  activeAt: '2020-05-12T04:00:00.000Z',
  createdAt: '2020-05-12T04:00:00.000Z',
  customPlans: [
    {
      __typename: 'Plan',
      id: 3,
      name: 'Summer as a Verb Nonironically',
    },
  ],
  id: 3,
  status: 'active',
};

export const deletedAccount = {
  __typename: 'Account',
  activeAt: '2020-04-10T04:00:00.000Z',
  createdAt: '2020-04-10T04:00:00.000Z',
  customPlans: [
    {
      __typename: 'Plan',
      id: 3,
      name: deletedAccountNickname,
    },
  ],
  id: 2,
  status: 'deleted',
};

export const getSessionDataMock = {
  user: {
    __typename: 'User',
    accounts: [firstActiveAccount, secondActiveAccount, deletedAccount],
    id: 10,
    name: 'Molly',
  },
};

export const EXCHANGE_TOKEN_REQUEST_NAME = 'ExchangeToken';

// NOTE: in auth-link apollo-client initiates logout if executing any operation
// besides 'ExchangeToken' without an auth token
export const EXCHANGE_TOKEN = gql`
  query ExchangeToken($loginToken: String!) {
    tokenExchange(loginToken: $loginToken) {
      token
    }
  }
`;

export const exchangeTokenMock = token => ({
  tokenExchange: {
    token,
  },
});
