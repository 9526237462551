import React from 'react';
import { useMenuTrigger, useButton } from 'react-aria';
import { CollectionElement, CollectionChildren } from '@react-types/shared';
import { useMenuTriggerState } from 'react-stately';
import { AccountAvatar } from 'components/account-avatar';
import { useAccountContext } from 'contexts/account';
import { NavButton, AvatarWrapper } from '../menu.style';
import { Popover } from '../../../../popover/popover.component';
import { MenuContent } from './menu-content.component';

interface Props {
  openSupportModal: () => void;
  children: (false | CollectionElement<unknown>)[];
}

export const MenuButton = (props: Props) => {
  const { selectedAccountId } = useAccountContext();
  const elementChildren = props.children.filter(
    Boolean
    // eslint-disable-next-line
  ) as CollectionChildren<object>;
  // Create state based on the incoming props
  const state = useMenuTriggerState({});

  // Get props for the button and menu elements
  const ref = React.useRef(null);
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref);

  const { buttonProps } = useButton(menuTriggerProps, ref);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <NavButton {...buttonProps} ref={ref} data-testid="trigger">
        <AvatarWrapper open={state.isOpen}>
          <AccountAvatar accountId={selectedAccountId} size="32px" />
        </AvatarWrapper>
      </NavButton>
      {state.isOpen && (
        <Popover width="186px" isOpen={state.isOpen} onClose={state.close}>
          <MenuContent
            openSupportModal={props.openSupportModal}
            {...menuProps}
            onClose={state.close}
          >
            {elementChildren}
          </MenuContent>
        </Popover>
      )}
    </div>
  );
};
