import styled from 'styled-components';
import { Button, Text } from '@arcadiapower/shrike';

export const StyledButton = styled(Button).attrs({ backgroundless: true })`
  border: none;
  padding: 8px;
  justify-content: flex-start;
`;

export const StyledText = styled(Text)`
  text-align: left;
`;
