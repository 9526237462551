import styled from 'styled-components';
import { IconButton } from '@arcadiapower/shrike';

export const ModalFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
`;
export const StyledIconButton = styled(IconButton)<StyleProps>`
  position: absolute;
  top: 18px;
  right: 18px;
`;

export const ModalWrapper = styled.div`
  position: relative;
`;
