export const FILL_MAP = {
  0: '#B7EDED',
  1: '#F7A3EC',
  2: '#C4FF45',
  3: '#00E3E4',
  4: '#F9F3E8',
};

export const getFillIndex = index => {
  let fillIndex = index;
  const maxLength = Object.keys(FILL_MAP).length;
  while (fillIndex > maxLength - 1) {
    fillIndex -= maxLength;
  }
  return fillIndex;
};
