import { css } from 'styled-components';

export const NAV_HEIGHT = '72px';
export const SUB_NAV_HEIGHT = '64px';
export const MOBILE_NAV_HEIGHT = '56px';
export const MAX_NAV_WIDTH = '1300px';
export const MAX_PRIMARY_PAGE_WIDTH = '1100px';
export const MAX_SECONDARY_PAGE_WIDTH = '648px';
export const MODAL_WIDTH = 480;

export const notificationWrapperStyles = () => css`
  ${props => props.theme.media.small`
    bottom: ${NAV_HEIGHT};
  `}
`;

// z-index values
// The react router modal container has a z-index of 1000. The notification wrapper in the Notification component in Shrike (used for toasts) has a z-index of 11. We will need to update this if we ever need to layer a toast on top of a modal.
// Just as an FYI, the z-index of the Help Widget in the Movers flow is 999998

export const zIndexBase = 1;
export const zIndexIncrement = 1;

export const zIndexSupportHeadshotsMiddleLayer = zIndexBase;
export const zIndexSupportHeadshotsTopLayer =
  zIndexSupportHeadshotsMiddleLayer + zIndexIncrement;

export const zIndexScrollableTermsButton = zIndexBase;

export const zIndexMobileNav = zIndexBase;
export const zIndexDesktopNav = zIndexMobileNav + zIndexIncrement;
export const zIndexStickyClosedBanner = zIndexDesktopNav + zIndexIncrement;
export const zIndexDropdownPopover = zIndexStickyClosedBanner + zIndexIncrement;
// The z-index of the shrike toast notification is 21
export const zIndexLocalizeWidget = 20;
