export const notifications = {
  heading: 'Notifications',
  currentPastModule: {
    accessibleTitles: {
      current: 'Current',
      past: 'Past',
    },
    links: {
      current: 'Current',
      past: 'Past',
    },
    emptyState: {
      current: {
        title: 'No new notifications',
        message: 'You’re all caught up on your updates and tasks.',
      },
      past: {
        title: 'Your past notifications will live here',
        message:
          'Any historical updates for your Arcadia account will appear here.',
      },
    },
  },
};
