import styled from 'styled-components';
import { bool, number, string } from 'prop-types';
import { GridCell, gridCellProps } from './grid-cell.style';
import { breakpointStyles, border, getPropTypes } from './utils';

export const containerStyles = props => ({
  alignItems: props.alignItems,
  display: props.flex ? 'flex' : props.display,
  flexDirection: props.direction,
  flexGrow: props.grow,
  flexWrap: props.wrap,
  gap: props.gap,
  justifyContent: props.justifyContent,
  textAlign: props.textAlign,
});

export const Container = styled(GridCell)<StyleProps>`
  ${containerStyles}
  ${border}
  ${breakpointStyles(containerStyles)};
`;

export const containerProps = {
  ...gridCellProps,
  alignItems: string,
  direction: string,
  flex: bool,
  gap: string,
  grow: number,
  justifyContent: string,
  textAlign: string,
  wrap: string,
};

Container.propTypes = getPropTypes(containerProps);
