export const usage = {
  heading: 'Usage',
  impact: {
    title: 'Environmental impact',
    description:
      'You’ve used %{kwh} kWh of clean and renewable electricity since joining Arcadia. That’s kept %{totalCarbon} lbs of carbon dioxide (CO2) from heating up our atmosphere.',
    content: {
      paragraphs: [
        'And CO2 isn’t the only greenhouse gas you’ve averted. The use of fossil fuels also releases huge quantities of methane (CH4) into our atmosphere. And while there’s less of it than CO2, methane is pound for pound 25 times worse for our climate.',
        'Anthropogenic greenhouse gases come from a variety of sources, but the biggest by far comes from burning fossil fuels such as coal, oil, and natural gas. These fossil fuels produce a ton of heat when burned, and we use that heat to create new forms of useful energy, like electricity. In fact, the generation of electricity is by far the biggest reason we burn fossil fuels. To meet our growing electricity needs in the US alone, each year we burn 37.1 quadrillion Btus worth of fossil fuels. Mechanical energy for transportation is second, at 28.2 quads.',
        'Since the Industrial Revolution, when we started using fossil fuels at much higher rates, the amount of CO2 in the atmosphere has increased by 43%. The amount of methane has doubled.',
        'Overall, electricity accounts for 26.3% of total greenhouse gas emissions in the US. That’s where your home energy use comes in. You may not think about it, but the energy you use every day at home has a big impact on your carbon footprint.',
        'If we want to reduce CO2 emissions and avoid the most devastating impacts of global warming and climate change, then we need to quickly reduce our fossil fuel consumption. That means finding ways to conserve energy through efficiency, and perhaps more importantly, switching to renewable fuel sources. By connecting your home to clean energy through Arcadia, you’re directly decreasing demand for fossil fuels. That means that more coal, oil, and natural gas can stay in the ground where they belong, and more greenhouse gases can stay out of the atmosphere.',
      ],
      captions: [
        'Energy we use releases greenhouse gasses',
        'Those gases trap heat from the sun, and the earth heats up, like a greenhouse',
        'Ice caps melt, oceans rise, extreme weather events get worse and more frequent',
      ],
    },
    banner: {
      description: 'lbs of CO2 avoided since you joined',
    },
    sources: [
      '1. "Climate Change Indicators: Atmospheric Concentrations of Greenhouse Gases" EPA.gov. US Environmental Protection Agency. Web. Accessed 16 September 2020.',
      '2. "Overview of Greenhouse Gases." EPA.gov. US Environmental Protection Agency. Web. Accessed 16 September 2020.',
      '3. "U.S. Energy Facts Explained." EIA.gov. US Energy Information Administration. 7 May 2020. Web. Accessed 14 September 2020.',
    ],
  },
  windDetails: {
    title: 'Energy source',
    alt: '%{projectType} farm',
    subtitle:
      'Since you joined, your home has been powered with %{kwh} kWh of clean and renewable electricity.',
    stats: {
      CO2: 'lbs of CO2 averted annually',
      kWh: 'clean kWh generated annually',
      homes: 'homes powered annually',
    },
  },
  energyAnalysis: {
    aria: {
      links: {
        back: 'Back to usage page',
      },
    },
    energyMix: {
      title1: 'Energy mix',
      title2: 'for United States',
    },
    title: 'Energy analysis',
    paragraph1:
      'Over the last %{months}, you’ve used %{kwh} kWh of clean and renewable electricity to sustainably power your home. That has helped keep tons of coal, oil, natural gas, and uranium buried in the ground.',
    paragraph2:
      'In the US, %{fossilFuelsPercent}% of our electricity comes from fossil fuels, which release greenhouse gases and contribute to climate change. But, the harmful effects of fossil fuels extend far beyond greenhouse gas emissions, however. Long before they make it to power plants, fossil fuels use huge amounts of energy and devastate local environments.',
    paragraph3:
      'Fossil fuels, and the uranium necessary for nuclear power, are trapped underground. Energy companies have found ways to extract large quantities of them, but those methods take a huge toll on the environment. The US gets most of its coal, for instance, from strip mining, in which layers of soil are stripped away with dynamite to get at the coal deposits underneath them. The process decimates wildlife habitats and pollutes waterways. Some 500 mountaintops in the Appalachian region have been destroyed due to strip mining.',
    paragraph4:
      'Most of our natural gas comes from hydraulic fracturing, better known as fracking. Fracking involves shooting water, chemicals, and sand into oil and gas wells at incredibly high pressures to force the gas to the surface. Sound worrying? The process can harm drinking water, cause air pollution, and even result in earthquakes.',
    paragraph5:
      'Land habitats aren’t the only ones at risk from fossil fuel extraction. Many oil wells are located offshore, where drilling for and transporting crude oil can result in oil spills. Most oil spills are small, but even those are harmful because oil is so toxic. Major spills are disasters. The Deepwater Horizon spill in April 2010, for isntance, spilled enough oil into the Gulf of Mexico to fill 200 Olympic-sized swimming pools. That much oil.',
    paragraph6:
      'It’s all a part of the price we pay for energy. And Electricity drives more demand for fossil fuels than any other sector by far, including Transportation.',
    paragraph7:
      'But there is another way. Because you power your home with clean and renewable energy, you are decreasing demand for fossil fuels. That means that more coal, oil, natural gas, and uranium can stay in the ground where they belong.',
    sources: [
      'Sources:',
      '1. “Coal and the Environment.” EIA.gov. US Energy Information Administration. 21 January 2020. Web. Accessed 10 September 2020.',
      '2. “Frequently Asked Questions.” EIA.gov. US Energy Information Administration. 17 June 2020. Web. Accessed 10 September 2020.',
      '3. Jedediah Britton-Purdy. “The Violent Remaking of Appalachia.” The Atlantic. 21 March 2016. Web. Accessed 10 September 2020.',
      '4. Melissa Denchak. “Fracking 101.” National Resources Defense Council. 19 April 2019. Web. Accessed 14 September 2020.',
      '5. “Oil Spills.” NOAA.gov. National Oceanic and Atmospheric Administration. August 2020. Web. Accessed 14 September 2020.',
    ],
  },
  energyDetails: {
    coal: {
      title: 'Coal',
      paragraph1:
        'In the US, %{percent} of our electricity comes from burning coal. Over the last %{months}, powering your home with clean and renewable energy has kept %{equivalency} %{units} of coal in the ground.',
      paragraph2:
        'Coal, once the powerhouse of the Industrial Revolution, has been a staple source of electricity in the United States. Between 1950 and 2015, it was the biggest power source; electricity generated from coal peaked in 2007 at 2,016 billion kilowatt-hours.',
      facts: [
        'Coal releases more carbon dioxide, a greenhouse gas, than any other source of fuel.',
        'Burning coal also releases harmful chemicals that cause acid rain, smog, and health problems.',
        'Strip mining destroys ecosystems and pollutes waterways, harming both people and wildlife.',
      ],
      paragraphs: [
        'Coal has the highest carbon content of any fuel source and releases more carbon dioxide than other fuel. Burning coal sends nearly twice as much CO2 into the atmosphere as burning natural gas, for example. That high carbon content gives it an outsized impact on greenhouse gas emissions, which contribute directly to climate change. In 2019, coal accounted for 11% of power consumed in the US but 21% of US energy-related greenhouse gas emissions.',
        'Burning this fossil fuel also releases other harmful chemicals, including sulfur dioxide (leads to acid rain), nitrogen oxides (create smog and cause respiratory illnesses), and particulates (cause lung disease and other adverse health effects).',
        'Coal is mined in two ways: underground mining, which involves cutting it from inside the earth, and strip mining (also known as surface mining), in which layers of soil are stripped away with dynamite to get at coal deposits underneath them. Some 500 mountaintops in the Appalachian region have been destroyed due to strip mining. The process decimates wildlife habitats and pollutes waterways. In 2012, the Environmental Protection Agency estimated that strip mining had destroyed 1.4 million acres of native forest in Appalachian coal country.',
        'When powerplants burn coal, it leaves residue called coal ash. In 2014, power plants generated nearly 130 million tons of coal ash, making it one of the largest types of industrial waste in the US. If it’s not disposed of properly, coal ash can leak harmful chemicals including mercury and arsenic into drinking water, ground water, and the air. Large coal ash spills near Kingston, Tennessee in 2008 and Eden, North Carolina in 2014, for instance, wreaked environmental and economic damage on the surrounding areas.',
        'As other sources of fuel, including renewables, get cheaper, coal is growing less popular. By supporting renewable energy, you’re helping to decrease demand for coal so that it can stay in the ground.',
      ],
      sources: [
        '1. “Coal and the Environment.” EIA.gov. US Energy Information Administration. 21 January 2020. Web. Accessed 10 September 2020.',
        '2. “Coal Ash Basics.” EPA.gov. US Environmental Protection Agency. Web. Accessed 11 September 2020.',
        '3. “Electricity in the United States.” EIA.gov. US Energy Information Administration. 20 March 2020. Web. Accessed 11 September 2020.',
        '4. “Energy and the Environment Explained.” EIA.gov. US Energy Information Administration. 11 August 2020. Web. Accessed 10 September 2020.',
        '5. “Frequently Asked Questions.” EIA.gov. US Energy Information Administration. 17 June 2020. Web. Accessed 10 September 2020.',
        '6. “Fossil Fuels: The Dirty Facts.” National Resources Defense Council. 29 June 2018. Web. Accessed 8 September 2020.',
        '7. Gabriel Popkins. “The Green Miles.” Washington Post Magazine. 13 February 2020. Web. Accessed 10 September 2020.',
        '8. Jedediah Britton-Purdy. “The Violent Remaking of Appalachia.” The Atlantic. 21 March 2016. Web. Accessed 10 September 2020.',
        '9. “What Are the Different Types of Coal?” American Geosciences Institute. Web. Accessed 10 September 2020.',
      ],
      total: {
        amount: '%{equivalency}',
        label: 'total %{units} of coal averted',
      },
    },
    gas: {
      title: 'Natural gas',
      paragraph1:
        'In the US, %{percent} of our electricity comes from burning natural gas. Over the last %{months}, powering your home with clean and renewable energy has kept %{equivalency} %{units} of natural gas in the ground.',
      paragraph2:
        'Natural gas has boomed as a source of energy in the United States since 2014. Today, the US is the world’s top natural gas producer and is expected to become a net energy exporter in 2020. In 2016, natural gas overtook coal as the largest source of electricity generation in the country; today, 32% of the energy consumed in the US comes from natural gas. That percentage is expected to increase through 2050 as natural gas becomes even cheaper.',
      facts: [
        'Natural gas is composed mostly of methane, a greenhouse gas that traps more heat than CO2 does.',
        'Most natural gas comes from fracking, an environmentally costly process.',
        'Flaring, or burning off excess gas, releases as many greenhouse gases a year as 75 million cars.',
      ],
      paragraphs: [
        'Natural gas is a fossil fuel composed mostly of methane, a greenhouse gas that traps 80 times more heat than carbon dioxide does. When natural gas is mined and burned, that methane escapes into the atmosphere, where it contributes directly to climate change.',
        'There are several types of natural gas: conventional gas, associated gas, and unconventional gas. Conventional gas (found in permeable rock beds) and associated gas (found with crude oil) can be mined with standard drilling, but mining unconventional gas (found in rock formations such as shale and sandstone) with standard drilling is too expensive. Instead, unconventional gas — the bulk of natural gas found in the US — is mined through hydraulic fracturing, or fracking.',
        'Fracking involves shooting water, chemicals, and sand into wells at incredibly high pressures to force natural gas to the surface. It’s a very water-intensive process; a single well can use 16 million gallons of water, which can hurt the local drinking supply. The wastewater from fracking can also pollute drinking water if it’s not managed carefully.',
        'Fracking releases chemicals including methane and toxic contaminants into the air. Those contaminants cause smog and adverse health effects such as birth defects, respiratory problems, and cancer. Fracking has also been linked to an increase in earthquakes in major fracking states such as Texas, Pennsylvania, Oklahoma, Ohio, and West Virginia.',
        'Because natural gas is so inexpensive, it’s sometimes cheaper for fossil fuel companies to burn it off than to transport it from gas fields. Burning off excess methane from gas wells is called flaring, while releasing natural gas directly into the atmosphere is called venting. In 2018, venting and flaring at one Texas oil field consumed more natural gas than either Arizona or South Carolina. The World Bank estimated that flaring released 350 million tons of greenhouse gases, or the equivalent of the emissions from 75 million cars, in the same year. Fossil fuel companies are supposed to be reducing flaring, but haven’t made much progress.',
        'Natural gas does release less carbon dioxide than other fossil fuels do, but its growing popularity is not without environmental risks. By supporting renewable energy, you’re helping to decrease the need for natural gas, and therefore the amount of methane released into the atmosphere.',
      ],
      sources: [
        '1. “Annual Energy Outlook 2018. EIA.gov. US Energy Information Administration. 6 February 2018. PDF. Accessed 14 September 2020.',
        '2. “Electricity in the United States.” EIA.gov. US Energy Information Administration. 20 March 2020. Web. Accessed 11 September 2020.',
        '3. “Frequently Asked Questions.” EIA.gov. US Energy Information Administration. 17 June 2020. Web. Accessed 10 September 2020.',
        '4. Hiroko Tabuchi. “Despite Their Promises, Giant Energy Companies Burn Away Vast Amounts of Natural Gas.” New York Times. 16 October 2019. Web. Accessed 14 September 2020.',
        '5. “Hydraulic Fracturing for Oil and Gas: Impacts from the Hydraulic Fracturing Water Cycle on Drinking Water Resources in the United States.” EPA.gov. US Environmental Protection Agency. December 2016. PDF. Accessed 14 September 2020.',
        '6. Kara Harris. “A Texas Town Takes on Fracking as a Racial Justice Issue.” Bloomberg CityLab. 28 August 2020. Web. Accessed 14 September 2020.',
        '7. Melissa Denchak. “Fracking 101.” National Resources Defense Council. 19 April 2019. Web. Accessed 14 September 2020.',
        '8. “Natural Gas Explained.” EIA.gov. US Energy Information Administration. 6 December 2019. Web. Accessed 14 September 2020.',
        '9. “Studies Link Earthquakes to Fracking in the Central and Eastern US.” ScienceDaily. 26 April 2019. Web. Accessed 14 September 2020.',
        '10. Tala Hadavi. “How Fracking Changed America Forever.” CNBC. 7 January 2020. Web. Accessed 14 September 2020.',
        '11. "U.S. Energy Facts Explained." EIA.gov. US Energy Information Administration. 7 May 2020. Web. Accessed 14 September 2020.',
        '12. “Where Our Natural Gas Comes From.” EIA.gov. US Energy information Administration. 13 November 2019. Web. Accessed 14 September 2020.',
      ],
      total: {
        amount: '%{equivalency}',
        label: 'total %{units} of natural gas averted',
      },
    },
    nuclear: {
      title: 'Nuclear',
      paragraph1:
        'In the US, %{percent} of our electricity comes from nuclear fission. Over the last %{months}, powering your home with clean and renewable energy has kept %{equivalency} %{units} of uranium in the ground.',
      paragraph2:
        'The United States generates more nuclear power than any other country in the world. Since 1990, nearly 20% of utility-scale electricity generation in the US has come from nuclear power. As of December 2019, the nation had 58 nuclear power plants across 29 states.',
      facts: [
        'Heat from nuclear fission powers turbines, which generate electricity.',
        'Nuclear power does not directly release any greenhouse gases.',
        'Contamination from radioactive nuclear waste is an environmental concern.',
      ],
      paragraphs: [
        'Nuclear power plants create electricity through a process called fission. Inside of a nuclear reactor, atoms are split into smaller atoms, releasing energy. That energy creates heat to boil water, which turns the blades of steam turbines. The turbines drive generators that produce electricity.',
        'Fission depends on ceramic pellets of uranium. Each pellet can produce as much energy as 150 gallons of oil. The pellets are stacked into metal fuel rods that are then grouped into bundles called fuel assemblies. Fuel assemblies live in the core of the nuclear reactor, where the fission takes place. To create the fuel pellets, uranium has to be mined, refined, and enriched. US commercial nuclear power reactors use 43 million pounds of uranium each year.',
        'Energy experts disagree about whether nuclear power is “clean” or not. Nuclear power generation does not release any greenhouse gases directly because no fossil fuels are burned. It does carry associated emissions, however; uranium mining requires a great deal of energy, and nuclear power plants are constructed of energy-heavy materials such as concrete and steel. Those strong materials are necessary because nuclear reaction could cause widespread air and water contamination without very strict safety protocols.',
        'After fuel assemblies are used in nuclear reactors, they become highly radioactive thanks to the uranium they contain. Disposing of spent fuel assemblies safely requires time and heavy regulations. First, the fuel assemblies must be stored underwater at the nuclear power plant for several years. The water keeps the assemblies cool and contains the radiation. After a few years, the assemblies can be moved to specially designed dry storage facilities.',
        'The spent uranium remains radioactive — and therefore harmful to humans and the environment — for thousands of years. Scientists are still trying to figure out a long-term storage solution for the more-than 90,000 metric tons of nuclear waste in the US. Ideally, it would be disposed of in permanent underground repositories, but the US has no such facilities.',
        'Exposure to radiation can cause burns and radiation sickness. Longer-term effects include cancer and cardiovascular disease. Scientists don’t fully understand radiation’s effect on the environment yet. Nuclear reactors and spent fuel assembly storage containers are designed to withstand extreme weather events and earthquakes, but accidents do happen. In March 2011, an earthquake and tsunami in Japan caused a nuclear accident at the Fukushima Daiichi facility, releasing radioactive waste into the surrounding area.',
        'Whether or not nuclear power is clean, questions about the environmental impact and long-term storage of radioactive waste remain. By supporting wind and solar energy, you’re choosing a truly clean, safer option.',
      ],
      sources: [
        '1. “Frequently Asked Questions.” EIA.gov. US Energy Information Administration. 17 June 2020. Web. Accessed 10 September 2020.',
        '2. “Fukushima Daiichi Accident.” World Nuclear Association. May 2020. Web. Accessed 15 September 2020.',
        '3. Mark Piesing. “How to Build a Nuclear Warning for 10,000 Years’ Time.” BBC. 3 August 2020. Web. Accessed 15 September 2020.',
        '4. Mitch Jacoby. “As Nuclear Waste Piles Up, Scientists Seek the Best Long-Term Storage Solutions.” Chemical & Engineering News. 30 March 2020. Web. Accessed 15 September 2020.',
        '5. “The Nuclear Fuel Cycle.” EIA.gov. US Energy Information Administration. 27 May 2020. Web. Accessed 15 September 2020.',
        '6. “Nuclear Power and the Environment.” EIA.gov. US Energy Information Administration. 15 January 2020. Web. Accessed 15 September 2020.',
        '7. “Nuclear Power Plants.” EIA.gov. US Energy Information Administration. 16 April 2020. Web. Accessed 15 September 2020.',
        '8. “Radiation Health Effects.” EPA.gov. US Environmental Protection Agency. Web. Accessed 15 September 2020.',
        '9. "U.S. Energy Facts Explained." EIA.gov. US Energy Information Administration. 7 May 2020. Web. Accessed 14 September 2020.',
        '10. "U.S. Nuclear Energy." EIA.gov US Energy Information Agency, 15 April 2020. Web. Accessed 15 September 2020.',
      ],
      total: {
        amount: '%{equivalency}',
        label: 'total %{units} of uranium averted',
      },
    },
    oil: {
      title: 'Oil',
      paragraph1:
        'In the US, %{percent} of our electricity comes from burning oil. Over the last %{months}, powering your home with clean and renewable energy has kept %{equivalency} %{units} of oil in the ground.',
      paragraph2:
        'Oil is a liquid fossil fuel that is vital to both the electricity and transportation sectors in the United States. Crude oil is refined into fuels such as gasoline, kerosene, and propane, and into petroleum products such as plastics.',
      facts: [
        'Burning oil for electricity and heat releases greenhouse gases into the atmosphere.',
        'Gasoline- and diesel-powered vehicles emit dangerous chemicals.',
        'Oil spills threaten plants and animals, humans, and local economies.',
      ],
      paragraphs: [
        'Power plants that burn petroleum to create electricity typically operate only when demand is very high because oil is more expensive than other fossil fuels and is subject to pollution regulations. For that reason, only 0.5% of utility-scale electricity generation in 2019 came from petroleum. However, oil accounted for 37% of energy consumption in 2019, thanks largely to the transportation sector. Many homes (14%) still depend on oil for heat, as well, especially in the Northeast.',
        'Oil can be found in underground reservoirs and closer to the surface in tar sands. Energy companies collect it through drilling or strip mining, which involves blasting layers of earth away to get at the oil underneath. Exploring and drilling for oil can damage marine and land habitats. Depending on the type of rock the crude oil is found in, energy companies may mine it using hydraulic fracturing, or fracking.',
        'Fracking involves shooting water, chemicals, and sand into wells at incredibly high pressures to force oil to the surface. The process can harm drinking water, cause air pollution, and even result in earthquakes.',
        'Burning oil does release greenhouse gases, including carbon dioxide, that contribute to climate change. Gasoline- and diesel-powered vehicles are the biggest contributors of carbon monoxide and nitrogen oxide, which causes smog and respiratory illnesses.',
        'Because many oil wells are found offshore, drilling for and transporting the crude oil sometimes result in oil spills. There are actually thousands of oil spills in US waters every year, but most are small — a boat spilling some gas when it fuels up, for instance. But some spills, such as pipeline breaks, shipwrecks, and drilling mishaps, turn into major disasters. The Deepwater Horizon spill in April 2010, for instance, killed 11 people and spilled about 134 million gallons of oil into the Gulf of Mexico. That’s more than 200 Olympic-sized swimming pools. More recently, a ship carrying nearly 4,000 tons of fuel ran aground near Mauritius in July 2020 and began leaking oil into the water.',
        'Oil is toxic, so it harms plants, animals, and people who come into contact with spills. It can cause heart damage, stunted growth, and even death. The damage causes economic pain, as well. After the Deepwater Horizon spill, the Gulf Coast tourism industry lost around $22.7 billion, while the commercial fishing industry lost $247 million.',
        'Clean energy advancements in both power generation and transportation should help drive down demand for oil in the coming years. When you support clean energy, you’re making sure that more oil can stay in the ground rather than in our air and our oceans.',
      ],
      sources: [
        '1. “Frequently Asked Questions.” EIA.gov. US Energy Information Administration. 17 June 2020. Web. Accessed 10 September 2020.',
        '2. “Oil and the Environment.” EIA.gov. US Energy Information Administration. 25 August 2020. Web. Accessed 14 September 2020.',
        '3. “Oil-fired power plants provide small amounts of U.S. electricity capacity and generation.” EIA.gov. US Energy Information Administration. 16 May 2017. Web. Accessed 14 September 2020.',
        '4. “Oil Spills.” NOAA.gov. National Oceanic and Atmospheric Administration. August 2020. Web. Accessed 14 September 2020.',
        '5. “Satellite images show oil spill disaster unfolding in Mauritius: ‘We will never be able to recover’." CBSNews. 8 August 2020. Web. Accessed 14 September 2020.',
        '6. “U.S. households’ heating equipment choices are diverse and vary by climate region.” EIA.gov. US Energy Information Administration. 6 April 2017. Web. Accessed 14 September 2020.',
      ],
      total: {
        amount: '%{equivalency}',
        label: 'total %{units} of oil averted',
      },
    },
  },
  recs: {
    title: 'Renewable energy certificates',
    description:
      'You’ve used %{kwh} kWh of clean and renewable energy since joining Arcadia. The underlying mechanism for that energy was retiring %{recs} RECs.',
    kwhText: 'RECs retired from %{kwh} clean kWh',
    content: {
      paragraphs: [
        'Before we start, it’s important to understand a key fact about our power grid. Namely, when electricity enters the grid, there’s absolutely no way to track where it goes. While that might sound surprising, it’s actually a really good thing, because it allows grid operators  to balance, distribute and deliver electricity across the country.',
        'That’s where Renewable Energy Certificates (RECs) come in. They were specifically created to track clean and renewable energy as it moves through our power grid. In fact, RECs are the only way to track renewable energy generation and consumption today. Anytime a company or an individual claims to be using renewable energy, they are in fact using RECs.',
        'Specifically, when a renewable energy generator, like a wind or solar farm, produces one megawatt-hour (MWh) of electricity, exactly one REC is created. That REC can now be purchased and retired by anyone who uses a MWh of electricity. When that happens, they have officially used that renewable energy. Arcadia partners with renewable energy generators to source, verify, purchase and most importantly retire RECs on your behalf. Remember, once a REC is retired, it can never be sold again, as you’ve officially consumed that renewable energy.',
        'When you purchase RECs, you are supporting renewable energy. For existing renewable energy generators, RECs are a critical source of income and a financial advantage. For developers, they are a financial incentive to build new renewable energy farms.',
        'Here’s how it works. Every power generator  sells power into the grid at the same price. But when the energy comes from a renewable energy source (e.g., wind, solar, hydro), a REC is also created, and can be sold to an energy consumer (like you). That’s the financial advantage for renewable energy generators. This advantage will also incentivize the building of new renewable energy farms, by strengthening the financial outlook over various fossil fuel alternatives (e.g., coal, oil, natural gas). Here’s another way to think about it: while we don’t have a carbon tax in the US today, RECs are a reciprocal advantage for carbon-free energy. And that’s a very powerful thing! As history has shown, one surefire way to change the world is by aligning the financial incentives towards the outcomes we want.',
        'Naturally, the magnitude of this financial incentive will depend on the price of a REC. Namely, the higher the price, the greater the incentive. But today, this price can vary dramatically. A single REC can cost a few pennies or a few hundred dollars. That means the most expensive RECs cost 1000x times more than the cheapest ones!',
        'You might be wondering why that is. Well, it’s because today, the demand for RECs is far lower than the supply. And when that happens in any market, the price is set by a wide range of inconsistent criteria that individual buyers value. And while some of those criteria make a lot of sense, others are arbitrary and unique to only that buyer. It’s worth noting that the primary buyers of RECs today are businesses and institutions that are seeking to meet their own renewable energy goals or targets, often based on regulatory requirements.',
        'To date, residential consumers haven’t really participated in the REC economy. And there’s a good reason for that. Namely, it’s complicated and hard. And that’s one of the reasons we started Arcadia, to make it easy for everyday people. Our bet is that once consumers start demanding RECs, supply and demand will come back to equilibrium, and economic forces will set a higher, more consistent price. And that’s a great thing for renewable energy generators and developers.',
        'For context, a normal US household will use roughly one MWh of electricity each month. That’s equivalent to one REC. The RECs we purchase and retire for your home energy will usually cost a few bucks, and that cost is included in your Arcadia membership. While we could purchase RECs that are far cheaper (1/100th the cost), we don’t. Instead we’ve established a set of criteria for our RECs that we believe positions our members to have the largest possible impact at the most affordable price. That is, once supply and demand are rebalanced.',
        'How we source, validate, purchase, and retire RECs (on your behalf) is really a reflection of our mission and our unique point of view about how to best achieve it.',
        'First, our mission is to accelerate the world’s transition to a 100% renewable energy future. And we want to do that as soon as possible, to avert the dire consequences of climate instability. Second, we believe everyday people are the key to this transition. And, when given an easy and affordable choice, they will choose clean and renewable energy every time.',
        'Those two core beliefs, impact and accessibility, inform everything we do at Arcadia, including how we source RECs. In other words, we attempt to maximize the financial incentives for renewable energy providers and, at the same time, keep the price of membership realistic for our members, because there is strength (and change) in numbers.',
        'In case you’re curious, this is the bar we use to ensure environmental impact when sourcing RECs. First, the energy comes from US wind and solar farms that were built within the last 15 years. Second, the RECs are purchased and retired in the same year as they are created. And third, the number of retired RECs match the sales of clean energy to Arcadia members. Finally, all of these criteria and claims are independently analyzed, audited, and verified each year by Deloitte.',
      ],
      captions: [
        'We sync with your utility to track your electricity usage',
        'We source, verify, purchase, and retire RECs for you',
        'You are now using clean and renewable energy each month',
      ],
      approach: {
        title: 'Our approach',
        captions: [
          {
            title: 'Power to the people',
            caption:
              'You get a say in where your energy comes from, and RECs are a great way to support renewable energy.',
          },
          {
            title: 'Affordable impact',
            caption:
              'We source our RECs by balancing an affordable monthly membership fee with meaningful environmental impact.',
          },
          {
            title: 'Independent verification',
            caption:
              'The impact criteria for our RECs is independently analyzed, audited and verified each year by Deloitte.',
          },
        ],
      },
    },
    links: {
      watchVideo: 'Watch EPA video about RECS',
    },
  },
};
