import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'styles';
import {
  Content,
  ExpandCollapseIcon,
  Wrapper,
  StyledButton,
} from './accordion.style';

interface ButtonProps {
  justifyContent?: string;
  padding?: string;
  margin?: Margin;
  fullWidth?: boolean;
  textAlign?: string;
}
interface Props {
  children:
    | React.ReactNode
    | (({ expanded }: { expanded: boolean }) => React.ReactNode);
  label: string | React.ReactNode;
  scrollIntoView?: boolean;
  contentPadding?: string;
  buttonProps?: ButtonProps;
}

export const Accordion = ({
  children,
  label,
  scrollIntoView = true,
  contentPadding,
  buttonProps,
}: Props) => {
  const contentRef = useRef<HTMLSpanElement | null>(null);
  const [expanded, setExpanded] = useState(false);
  const handleInteraction = () => setExpanded(!expanded);

  useEffect(() => {
    let scrollTimeout;
    if (expanded && scrollIntoView)
      scrollTimeout = setTimeout(
        () => contentRef.current!.scrollIntoView({ behavior: 'smooth' }),
        200
      );
    return () => clearTimeout(scrollTimeout);
  }, [expanded, scrollIntoView]);
  const {
    padding,
    justifyContent: justifyButton = 'space-between',
    margin,
    fullWidth = true,
    textAlign: textAlignButton,
  } = buttonProps || {};
  const renderChildren =
    typeof children === 'function' ? children({ expanded }) : children;

  return (
    <Wrapper>
      <span ref={contentRef} />
      <StyledButton
        onClick={handleInteraction}
        padding={padding}
        margin={margin}
        fullWidth={fullWidth}
      >
        <Container
          flex
          justifyContent={justifyButton}
          width="100%"
          alignItems="center"
          textAlign={textAlignButton}
        >
          <div>{label}</div>
          <ExpandCollapseIcon
            expanded={expanded}
            color="primary"
            icon="ChevronDown"
            margin={{ left: '8px' }}
          />
        </Container>
      </StyledButton>
      <Content
        expanded={expanded}
        aria-hidden={!expanded}
        contentPadding={contentPadding}
      >
        {renderChildren}
      </Content>
    </Wrapper>
  );
};
