import React, { useContext, useState, useEffect } from 'react';
import { copyFor } from 'config';
import { Radio, Skeleton, Notification } from '@arcadiapower/shrike';
import { useUser } from 'hooks/use-user.hook';
import { useMutation } from '@apollo/client';
import { useAsyncFn } from 'react-use';
import { NOTIFICATION_DISPLAY_TIME } from 'domain/ux';
import { RadioGroup } from './radio-group.component';
import { UPDATE_PREFERENCE_MUTATION } from './language-pref.api';
import { StyledText, StyledContainer } from './language-pref.style';

const getCopy = copyFor('navigation.navBar.aria');

interface Props {
  type: 'desktop' | 'mobile';
}

export const LanguagePreferences = ({ type }: Props) => {
  const { addNotification } = useContext(Notification.Context);
  const [mutate] = useMutation(UPDATE_PREFERENCE_MUTATION);

  const { data: user, loading: userLoading } = useUser();
  const [language, setLanguage] = useState<any>(user?.languageShortCode);

  useEffect(() => {
    const userLanguage = user?.languageShortCode;
    if (userLanguage && language !== userLanguage) {
      submit();
    }
  }, [language, history]);

  const handleLanguageChange = selectedLanguage => {
    setLanguage(selectedLanguage);
  };

  const [{ error: mutationError, loading: mutationLoading }, submit] =
    useAsyncFn(async () => {
      await mutate({
        variables: {
          id: user?.id,
          input: {
            languageShortCode: language,
          },
        },
      });
      window.Localize?.setLanguage(language);
      addNotification({
        displayTime: NOTIFICATION_DISPLAY_TIME,
        message: getCopy('preference.toast'),
        variant: 'success',
      });
    }, [mutate, language, user, addNotification]);

  useEffect(() => {
    if (mutationError) {
      addNotification({
        displayTime: NOTIFICATION_DISPLAY_TIME,
        message: getCopy('preference.error'),
        variant: 'error',
      });
    }
  }, [mutationError]);

  if (mutationLoading || userLoading)
    return (
      <Skeleton
        height="100px"
        width="175px"
        opacity="low"
        data-testid="loading"
        margin={{ left: '10px' }}
      />
    );

  return (
    <StyledContainer
      margin={type === 'desktop' ? '10px' : '0'}
      data-testid="preference-selector"
    >
      <RadioGroup legend={getCopy('preference.label')}>
        <Radio
          margin={{ top: '16px' }}
          checked={language === 'en'}
          name="English"
          onChange={() => {
            handleLanguageChange('en');
          }}
          value="en"
          highlightSelected={true}
        >
          <StyledText>{getCopy('preference.en')}</StyledText>
        </Radio>
        <Radio
          margin={{ bottom: '16px', top: '17px' }}
          checked={language === 'es'}
          name="Español"
          onChange={() => {
            handleLanguageChange('es');
          }}
          value="es"
          highlightSelected={true}
        >
          <StyledText>{getCopy('preference.es')}</StyledText>
        </Radio>
      </RadioGroup>
    </StyledContainer>
  );
};
