import React, { useRef } from 'react';
import { AriaSelectProps } from '@react-types/select';
import { HiddenSelect, useSelect, useButton } from 'react-aria';
import { useSelectState } from 'react-stately';
import { Label, Icon } from '@arcadiapower/shrike';
import { copyFor } from 'config';
import { ListBox } from './components/list-box.component';
import { Popover } from './components/popover.component';
import { Button, Wrapper } from './dropdown.style';

const getCopy = copyFor('dropdown');

export const Dropdown = <T extends Record<string, unknown>>(
  props: { optionClassName?: string } & AriaSelectProps<T>
) => {
  // Create state based on the incoming props
  const state = useSelectState(props);

  // Get props for child elements from useSelect
  const ref = useRef(null);
  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref
  );

  // Get props for the button based on the trigger props from useSelect
  const { buttonProps } = useButton(triggerProps, ref);

  return (
    <Wrapper>
      <Label
        className="notranslate"
        fontFamily="medium"
        fontSize={300}
        margin={{ bottom: '2px', left: '7px' }}
        {...labelProps}
        color="secondary"
      >
        {props.label}
      </Label>
      <HiddenSelect
        state={state}
        triggerRef={ref}
        label={props.label}
        name={props.name}
      />
      <Button {...buttonProps} ref={ref}>
        <span {...valueProps}>
          {state.selectedItem
            ? state.selectedItem.rendered
            : getCopy('placeholder')}
        </span>
        <Icon icon="ChevronDown" aria-hidden="true" margin={{ left: '10px' }} />
      </Button>
      {state.isOpen && (
        <Popover isOpen={state.isOpen} onClose={state.close}>
          <ListBox
            {...menuProps}
            state={state}
            optionClassName={props.optionClassName}
          />
        </Popover>
      )}
    </Wrapper>
  );
};
