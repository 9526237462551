import styled from 'styled-components';
import { Button } from '@arcadiapower/shrike';
import { Container } from 'styles';
import { zIndexMobileNav, MOBILE_NAV_HEIGHT } from 'domain/style';

export const MobileWrapper = styled(Container).attrs(() => ({
  alignItems: 'center',
  flex: true,
  justifyContent: 'space-between',
}))<StyleProps>`
  background: ${props => props.theme.colors.background.primary};
  border-bottom: ${props =>
    `1px solid ${props.theme.colors.background.tertiary}`};
  box-shadow: 0px 1px 8px ${props => props.theme.colors.background.tertiary};
  height: ${MOBILE_NAV_HEIGHT};
  padding: 0 16px;
  width: 100%;
`;

export const Nav = styled.nav<StyleProps>`
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: ${props =>
    `1px solid ${props.theme.colors.background.tertiary}`};
  z-index: ${zIndexMobileNav};
`;

export const StyledButton = styled(Button).attrs({
  backgroundless: true,
})`
  border: none;
  padding: 8px;
`;
