export const ELEMENT_TYPES = {
  PRIMARY_ACTION_BUTTON: 'Primary Action Button',
  SECONDARY_ACTION_BUTTON: 'Secondary Action Button',
  MODAL_FLOW: 'Modal Flow',
  ROW_BUTTON: 'Row Button',
};

// SECONDARY FLOWS
export const LOCATIONS = {
  BILLING_DETAILS: 'Billing Details',
  PAYMENT_INFORMATION: 'Payment Information',
  DASHBOARD: 'Dashboard',
  PLAN_DETAILS: 'Plan Details',
  SETTINGS: 'Settings',
};

interface BasicEventProperies {
  // Avoid adding extra properties here that are not shared across events.
  // Pass those through the stringified metadata instead
  elementName: string;
  elementType: string;
  location: string;
  parentElementName?: string;
}

export type ElementMap = BasicEventProperies | { [key: string]: ElementMap };
