import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

export const StyledPopover = styled.div`
  position: absolute;
  margin-top: 5px;
  margin-left: -142px;
  animation: ${animation} 0.3s;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 8px 0;
  background: ${props => props.theme.colors.background.primary};
  border-radius: ${props => props.theme.borderRadii.large};
`;
