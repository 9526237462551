import styled, { css } from 'styled-components';
import { Text, Spacer, Button } from '@arcadiapower/shrike';
import { Container } from 'styles';

export const AvatarWrapper = styled(Container).attrs({
  height: '40px',
  width: '40px',
})<StyleProps>`
  border: 4px solid ${props => props.theme.colors.background.primary};
  border-radius: ${props => props.theme.borderRadii.circular};

  ${props =>
    props.open &&
    `border-color: ${props.theme.colors.background.tertiary};
    `}
`;

export const NavButton = styled(Button).attrs({
  backgroundless: true,
  margin: { bottom: '2px' },
})`
  border: none;
  padding: 8px;

  &:hover {
    background: inherit;
  }
`;

export const StyledItem = styled.li`
  padding: 6px 16px;
  border: 2px solid transparent;

  &:focus {
    outline: none;
    background: ${props => props.theme.colors.background.secondary};
    border: 2px solid ${props => props.theme.colors.background.controls};
    cursor: pointer;
  }
`;

const sharedListStyles = css`
  width: 100%;
  margin: 0;

  &:focus {
    outline: none;
  }
`;

export const StyledList = styled.ul`
  ${sharedListStyles}
`;

export const StyledContainer = styled(Container).attrs({
  flex: true,
  alignItems: 'center',
})`
  width: 100%;
`;

export const AccountsWrapper = styled.ul`
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  ${sharedListStyles}
`;

export const StyledText = styled(Text).attrs({
  textStyle: 'paragraph400',
})`
  width: 100px;
  display: inline-block;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  margin-left: 8px;
`;

export const StyledSpacer = styled(Spacer)`
  padding: 0;
`;

export const StyledSelectedAccountText = styled(Text)`
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: center;
  padding: 0 16px;
`;
