import React from 'react';
import { Page } from 'styles';
import { BackLink } from 'components/back-link';
import { ErrorContent } from '../content';
import { Text } from './page.style';

interface Props {
  location?: string;
  title?: string;
  to?: string;
  variant?: string;
}

export const ErrorPage = ({ title, location, to, variant }: Props) => {
  return (
    <>
      {to && <BackLink to={to} location={location} />}
      <Page data-testid="error-page">
        <Text tag="h1" textStyle="heading800">
          {title}
        </Text>
        <ErrorContent variant={variant} />
      </Page>
    </>
  );
};
