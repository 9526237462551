import React from 'react';
import { Container } from 'styles';
import { copyFor } from 'config';
import { Icon } from '@arcadiapower/shrike';
import { getPlanLabel } from '../plan-switcher.component';
import { StyledButton, StyledText } from './list.style';
import { PlanSwitcherPlan } from '../plan-switcher.api';

interface Props {
  selectedPlanId?: number;
  plans: PlanSwitcherPlan[];
  onClick: (id: string) => void;
  className?: string;
}
const getCopy = copyFor('planSwitcher.list');

export const List = ({ plans, onClick, selectedPlanId, className }: Props) => {
  return (
    <Container
      flex
      direction="column"
      margin={{ bottom: '20px' }}
      className={className}
    >
      {plans.map(plan => {
        const isSelectedPlan = plan.id === selectedPlanId;
        const textStyle = isSelectedPlan ? 'heading400' : 'paragraph400';
        const planLabel = getPlanLabel(plan);
        return (
          <StyledButton
            aria-label={getCopy('button', { planLabel })}
            key={`button-switch-${plan.id}`}
            onClick={() => onClick(plan.id.toString())}
            margin="4px 24px"
          >
            {isSelectedPlan && (
              <Icon
                data-testid="checkmark"
                icon="Checkmark"
                scale={2}
                margin={{ left: '-22px', right: '6px' }}
              />
            )}
            <StyledText textStyle={textStyle} margin={{ left: '6px' }}>
              {planLabel}
            </StyledText>
          </StyledButton>
        );
      })}
    </Container>
  );
};
