import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';
import { Container } from 'styles';

// TODO: Move styled fieldset and legend to Shrike
// https://arcadiapower.atlassian.net/browse/DASH-1388
export const StyledFieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

export const StyledLegend = styled.legend<StyleProps>`
  color: ${props => props.theme.colors.content[props.variant]};
  font-family: ${props => props.theme.fontFamilies.main.medium};
  -webkit-font-smoothing: antialiased;
  font-size: ${props => props.theme.fontSizes[props.fontSize]};
  line-height: ${props => props.theme.lineHeights.medium};
  opacity: ${props => props.theme.opacities[props.opacity]};
`;

export const StyledText = styled(Text).attrs({
  textStyle: 'heading400',
})`
  width: 100px;
  display: inline-block;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  margin-left: 8px;
`;

export const StyledContainer = styled(Container).attrs({
  flex: true,
  alignItems: 'center',
})`
  width: 100%;
`;
