import React from 'react';
import { useMenuTrigger, useButton } from 'react-aria';
import { useMenuTriggerState } from 'react-stately';
import { Icon, Text } from '@arcadiapower/shrike';
import { useUser } from 'hooks/use-user.hook';
import { NavButton, AvatarWrapper } from './preferences.style';
import { Popover } from '../../../popover/popover.component';
import { LanguagePreferences } from '../../language-pref/index';

export const Preferences = () => {
  // Create state based on the incoming props
  const state = useMenuTriggerState({});
  // Get props for the button and menu elements
  const ref = React.useRef(null);
  const { menuTriggerProps } = useMenuTrigger({}, state, ref);
  const { buttonProps } = useButton(menuTriggerProps, ref);
  const { data: user } = useUser();

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <NavButton {...buttonProps} ref={ref}>
        <AvatarWrapper>
          <Icon icon="Globe" color="secondary" scale={2} />
        </AvatarWrapper>
        <div style={{ borderRight: '1px solid #d1d7d6' }}>
          <Text
            textStyle="heading500"
            tag="p"
            margin={{ right: '18px', left: '8px', top: '4px', bottom: '4px' }}
          >
            {user?.languageShortCode === 'en' ? 'English' : 'Español'}
          </Text>
        </div>
      </NavButton>
      {state.isOpen && (
        <Popover
          width="200px"
          height="125px"
          isOpen={state.isOpen}
          onClose={state.close}
        >
          <LanguagePreferences type="desktop" />
        </Popover>
      )}
    </div>
  );
};
