import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Notification } from '@arcadiapower/shrike';
import { ModalContainer } from 'react-router-modal';
import { SessionProvider } from 'contexts/session';
import { AccountProvider } from 'contexts/account';
import { ScrollToTop } from 'components/scroll-to-top';
import { ErrorBoundary } from 'components/error-boundary';
import { notificationWrapperStyles } from 'domain/style';
import { GlobalStyle } from 'styles';
import { Pages } from 'pages';
import { config } from 'config';
import { apolloClient } from './apollo-client';

export const App = () => (
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={config.theme}>
        <ErrorBoundary>
          <GlobalStyle />
          <SessionProvider>
            <AccountProvider>
              <Notification.Provider
                wrapperProps={{ styles: notificationWrapperStyles }}
              >
                <ModalContainer />
                <Pages />
              </Notification.Provider>
            </AccountProvider>
          </SessionProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  </ApolloProvider>
);
