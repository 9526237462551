import { sortByDate } from '@arcadiapower/warbler';
import { copyFor } from 'config';

const INACTIVE = 'inactive';
const ACTIVE = 'active';

export const isInactive = (status: string): boolean => status === INACTIVE;
export const isActive = (status: string): boolean => status === ACTIVE;

type PlanWithStatus = {
  status: string;
};

export const allPlansClosed = <T extends PlanWithStatus>(plans: T[]): boolean =>
  plans.every(plan => isInactive(plan.status));

export const getActivePlans = <T extends PlanWithStatus>(plans: T[]): T[] =>
  plans.filter(plan => !isInactive(plan.status));

type AccountWithPlans = {
  id: number;
  customPlans: {
    id: number;
  }[];
};

export const getPlansFromAccounts = <T extends AccountWithPlans>(
  accounts: T[]
): T['customPlans'] => {
  return accounts.reduce(
    (plans, account) => plans.concat(account.customPlans),
    [] as T['customPlans']
  );
};

type SortablePlan = {
  id: number;
  createdAt: Date;
  utilityAccounts: {
    id: number;
    utility: {
      id: number;
    };
  }[];
};

const sortPlansByDate = <T extends SortablePlan>(plans: T[]): T[] =>
  sortByDate(plans, 'createdAt').reverse();

export const sortActiveAndClosedPlans = <
  T extends SortablePlan & { status: string }
>(
  plans: T[]
): T[] => {
  const activeAndClosed = sortPlansByDate([...plans]).reduce(
    (accumulator: { active: T[]; closed: T[] }, plan) => {
      if (isInactive(plan.status)) {
        accumulator.closed.push(plan);
      } else {
        accumulator.active.push(plan);
      }
      return accumulator;
    },
    { active: [], closed: [] }
  );
  return [...activeAndClosed.active, ...activeAndClosed.closed];
};

// TODO: https://www.notion.so/approduct/Build-Backfill-Billing-Experience-Type-f630af45c8a84fa5bdf0d7308d8dba19
export const UCB_PLAN_TEMPLATE_IDS = [145, 211];
export const isOnUtilityConsolidatedBillingPlanTemplate = (
  planTemplateId: number
): boolean => {
  return UCB_PLAN_TEMPLATE_IDS.includes(planTemplateId);
};

// TODO: https://www.notion.so/approduct/Build-Backfill-Billing-Experience-Type-f630af45c8a84fa5bdf0d7308d8dba19
export const DUAL_BILLING_PLAN_TEMPLATE_IDS = [343, 344, 510, 543, 576];
export const VNM_STANDARD_PLAN_TEMPLATE_ID = 178;
export const isOnDualBillingPlanTemplate = (
  planTemplateId: number
): boolean => {
  return DUAL_BILLING_PLAN_TEMPLATE_IDS.includes(planTemplateId);
};

type LitePlan = {
  id: number;
  name?: string | null;
};

const getPlanNameCopy = copyFor('planSwitcherLite');
export const litePlanName = <T extends LitePlan>(plan: T) =>
  plan.name || getPlanNameCopy('defaultName', { id: plan.id });
