import { useContext } from 'react';
import { ClientError } from 'utils/errors/client-errors';
import { Session } from './session.context';

// This hook is helpful for typescript because it returns an object of type SessionContext.
// If we were to just use useContext(Session), it would return SessionContext | undefined
// and we'd have to assert that it is defined everywhere we use it
export const useSessionContext = () => {
  const context = useContext(Session);
  if (!context) {
    throw new ClientError(
      'useSessionContext must be used within a SessionProvider'
    );
  }
  return context;
};
