import React from 'react';
import { Container } from 'styles';
import { CardContent } from './content.style';
import { ErrorText } from './error-text.component';

interface Props {
  variant?: string;
}

export const ErrorContent = ({ variant = 'primary' }: Props) => {
  return (
    <CardContent variant={variant} data-testid="error-content">
      <Container flex direction="column" alignItems="center">
        <ErrorText variant={variant} />
      </Container>
    </CardContent>
  );
};
