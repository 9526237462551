import React from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { useSessionContext } from 'contexts/session';
import { routes } from 'config';
import { getPlansFromAccounts } from 'domain/plans';
import { amendPlanToDeepLink } from './legacy-deep-link-redirect.utils';
import { GET_REDIRECT_PLAN_INFO } from './legacy-deep-link-redirect.api';

export const LegacyDeepLinkRedirect = ({ history }: RouteComponentProps) => {
  const { dashboard } = routes();
  const { userId } = useSessionContext();
  const redirectToDashboard = <Redirect to={dashboard} />;
  const { data, error } = useQuery<
    GetRedirectPlanInfoQuery,
    GetRedirectPlanInfoQueryVariables
  >(GET_REDIRECT_PLAN_INFO, {
    variables: { id: userId },
  });
  if (error) return redirectToDashboard;
  if (data) {
    const plans = getPlansFromAccounts(data.user.accounts);
    if (plans.length > 1) return redirectToDashboard;
    else
      return (
        <Redirect
          to={amendPlanToDeepLink(history.location.pathname, plans[0].id)}
        />
      );
  }
  return null;
};
