import React from 'react';
import { Text } from '@arcadiapower/shrike';
import { Container, Page } from 'styles';
import { copyFor } from 'config';
import { ErrorText, ErrorIcon } from './app.style';

const getCopy = copyFor('error');

export const ErrorApp = () => {
  return (
    <Page margin="auto" data-testid="error-app">
      <Container
        flex
        direction="column"
        alignItems="center"
        margin="76px auto"
        maxWidth="448px"
      >
        <ErrorIcon
          aria-label={getCopy('warningIconLabel')}
          data-testid="warning-icon"
        />
        <Text tag="h1" textStyle="heading800" margin="44px 0 8px">
          {getCopy('headerText')}
        </Text>
        <ErrorText>{getCopy('bodyText')}</ErrorText>
      </Container>
    </Page>
  );
};
