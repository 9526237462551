export const dashboard = {
  standardDashboard: {
    welcome: {
      default: 'Welcome back, %{firstName}',
    },
    title: {
      multiPlan: 'Here are your plans at a glance.',
      singlePlan: 'Here is your plan at a glance.',
    },
    impactModules: {
      title: 'Your solar impact',
      emptyPlaceholder: '—',
      solarSavings: {
        title: 'Lifetime solar savings',
        badge: '+%{savings} last bill',
        tooltip: {
          label: 'lifetime solar savings definition',
          content:
            'This is the total solar savings you’ve received across your plans since signing up with Arcadia.',
        },
        emptyState: 'Track your total savings here.',
      },
      energyProduction: {
        title: 'Lifetime solar energy produced',
        kwh: '%{kwh} kWh',
        badge: '+%{kwh} last bill',
        tooltip: {
          label: 'lifetime kwh definition',
          content:
            'This is the total kWh of solar energy you’ve helped generate across all solar farms you’re a part of. ',
        },
        emptyState:
          'Find the total kWh of solar energy you’ve helped generate across all your solar farms here.',
      },
      energyEquivalency: {
        text: 'That’s enough clean energy to power a home for %{equivalent}.',
        emptyState: 'See your solar impact here.',
      },
    },
    plans: {
      title: {
        multiPlan: 'Your plans',
        singlePlan: 'Your plan',
      },
      downloadButton: 'Account summary',
      vnmStatus: {
        pending: {
          title: 'You’re on the list for a solar farm spot',
          text: 'We’re working to find a local solar farm for you. Stay tuned!',
        },
        waitlistNotLive: {
          title: 'Expect solar savings around %{timeframe}',
          text: 'Your solar farm launches in %{time}, so expect your first solar savings 1-2 months afterwards.',
        },
        waitlistLive: {
          title: 'Expect solar savings in 1-2 months',
          text: 'You’ll see your solar credits once your first Arcadia statement with your solar farm is ready.',
        },
        firstSolarStatement: {
          title: 'You’ve received your first solar credits!',
          text: 'Great news! You’re now receiving %{percentSavings}% of the solar farm savings on your Arcadia statements for being a part of %{projectName}.',
        },
      },
    },
    closedPlans: {
      title: 'Inactive plans',
      ariaButtonLabel: '%{action} inactive plans list view',
    },
    listItem: {
      ariaLabel: 'View billing for %{planName}',
      subtitle:
        '<span class="notranslate">%{utilityDisplayName} %{utilityAccountNumber} %{address}</span>',
      newBill: 'New bill',
      reactivateButton: 'Reactivate plan',
    },
    notifications: {
      accessibleTitle: 'Notifications',
      carousel: {
        buttons: {
          previous: 'Previous notification',
          next: 'Next notification',
          inner: '%{index} of %{length}',
        },
      },
    },
    utilitySyncModal: {
      title: 'Reconnect Utility Account',
      msg1: 'There was an error connecting to your utility account.',
      msg2: "To ensure you're able to receive one single consolidated bill, please reconnect. If you don't take action, you'll be on dual billing.",
      ctaText: 'Enter Utility Login Credentials',
      close: 'close-btn',
    },
  },
  whaleDashboard: {
    title: 'Your plans',
    listItem: {
      ariaLabel:
        'view solar page for <span class="notranslate">%{planName}</span>',
    },
  },
  waitlistDashboard: {
    title: 'Community Solar in %{state}!',
    subTitle:
      'You’re on your way to bringing more solar energy to %{state}! Check your status below or read our',
    link: 'guide to community solar',
    header: 'You’re on the waitlist!',
    subHeader:
      'We’ll let you know when we’re in the area and keep you updated with the latest information along the way.',
  },
};
