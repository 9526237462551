import styled from 'styled-components';
import { Icon, Text } from '@arcadiapower/shrike';

export const ErrorText = styled(Text)`
  text-align: center;
`;

export const ErrorIcon = styled(Icon).attrs({
  icon: 'ExclamationCircleInverted',
  margin: { left: '8px' },
})`
  height: 64px;
  width: 64px;
`;
