import React from 'react';
import { Item, Section } from 'react-stately';
import { copyFor } from 'config';
import { useSwitchAccount } from 'hooks/use-switch-account.hook';
import { getAccountLabel } from 'domain/account-statuses';
import { useAccountContext } from 'contexts/account';
import { MenuButton } from './components/menu-button.component';

interface Props {
  openSupportModal: () => void;
}

const getCopy = copyFor('navigation.navBar');

export const Menu = ({ openSupportModal }: Props) => {
  const { selectedAccount } = useAccountContext();
  const { selectableAccounts, showAccountSwitcher } = useSwitchAccount();

  const accountSwitcherItems = selectableAccounts.map(account => ({
    key: account.id.toString(),
    name: getAccountLabel(account),
  }));

  return (
    <MenuButton openSupportModal={openSupportModal}>
      {showAccountSwitcher && (
        <Section
          key="accountSwitcher"
          items={accountSwitcherItems}
          aria-label={getCopy('aria.switchAccount', {
            accountLabel: getAccountLabel(selectedAccount),
          })}
        >
          {item => <Item>{item.name}</Item>}
        </Section>
      )}
      <Section key="navigation">
        <Item key="settings">{getCopy('menu.settings')}</Item>
        <Item key="help">{getCopy('menu.help')}</Item>
      </Section>
      <Section key="logout">
        <Item key="logout">{getCopy('menu.logout')}</Item>
      </Section>
    </MenuButton>
  );
};
