import styled from 'styled-components';
import { Text as ShrikeText } from '@arcadiapower/shrike';

export const Text = styled(ShrikeText)`
  margin-bottom: 88px;

  ${props => props.theme.media.small`
    margin-bottom: 40px;
  `}
`;
