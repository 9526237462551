import React from 'react';
import { Icon, Text } from '@arcadiapower/shrike';
import { routes, copyFor } from 'config';
import { IconProps } from 'types/style';
import { useAccountNotifications } from 'hooks/use-account-notifications.hook';
import { NotificationKey, isHigh } from 'domain/notifications';
import { NavLink } from '../nav-link';
import { NavLinkTab } from '../nav-link-tab';
import { Circle, Wrapper } from './notification-icon-link.style';

interface Props {
  iconColor?: IconProps['color'];
  type?: 'desktop' | 'mobile' | 'sideNav';
  onClose?: () => void;
}

const { notifications } = routes();
const getCopy = copyFor('navigation.navBar');

export const NotificationIconLink = ({
  iconColor,
  type = 'mobile',
  onClose,
}: Props) => {
  const { data } = useAccountNotifications();

  const unfilteredCurrentNotifications = data?.currentNotifications || [];
  const currentNotifications =
    unfilteredCurrentNotifications?.filter(
      notification =>
        notification.notificationKey !== NotificationKey.lowIncomeHousehold
    ) || [];
  const length = currentNotifications.length;
  const overLengthLimit = length > 99;
  const lengthCopy = overLengthLimit ? '9+' : length;
  const fontSize = length < 10 ? '12px' : '10px';
  const someHighPriority = currentNotifications.some(notification =>
    isHigh(notification.priorityType)
  );

  const iconAndBadge = (
    <Wrapper aria-hidden={true}>
      <Icon icon="UINotification" color={iconColor} />
      {!!lengthCopy && (
        <Circle
          someHighPriority={someHighPriority}
          data-testid="badge"
          fontSize={fontSize}
        >
          <Text textStyle="heading300" color="primaryInverse">
            {lengthCopy}
          </Text>
        </Circle>
      )}
    </Wrapper>
  );

  const ariaLabel = length
    ? getCopy('aria.newNotifications', { length })
    : getCopy('links.notifications');

  if (type === 'desktop') {
    return (
      <NavLinkTab
        margin={{ right: '20px' }}
        path={notifications}
        padding="20px 8px"
        htmlProps={{
          'aria-label': ariaLabel,
        }}
        textHover={false}
      >
        {iconAndBadge}
      </NavLinkTab>
    );
  } else if (type === 'sideNav') {
    return (
      <NavLink path={notifications} onClick={onClose} ariaLabel={ariaLabel}>
        {iconAndBadge}
        <Text textStyle="heading500" color="primary" margin={{ left: '12px' }}>
          {getCopy('links.notifications')}
        </Text>
      </NavLink>
    );
  } else {
    return (
      <NavLink
        margin="0px"
        path={notifications}
        ariaLabel={ariaLabel}
        textHover={false}
      >
        {iconAndBadge}
      </NavLink>
    );
  }
};
