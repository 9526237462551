import React from 'react';
import { useWindowSize } from 'react-use';
import { config } from 'config';
import { DesktopNav } from './desktop-nav';
import { MobileNav } from './mobile-nav';

export { SubNav } from './sub-nav';

const { breakpoints } = config.theme.media;

export const Navigation = () => {
  const { width } = useWindowSize();
  return width > breakpoints.small ? <DesktopNav /> : <MobileNav />;
};
