import React from 'react';
import { copyFor } from 'config';
import { Icon, Text } from '@arcadiapower/shrike';

const getCopy = copyFor('modules.error');

interface Props {
  variant?: string;
  iconMargin?: Margin;
}
const darkVariants = ['accent6', 'primaryInverse'];

export const ErrorText = ({ variant = 'primary', iconMargin }: Props) => {
  const elementColor = darkVariants.includes(variant)
    ? 'primaryInverse'
    : 'primary';
  return (
    <>
      <Icon
        icon="ExclamationCircleInverted"
        margin={iconMargin || { bottom: '16px' }}
        scale={3}
        color={elementColor}
      />
      <Text color={elementColor}>{getCopy('line1')}</Text>
      <Text color={elementColor}>{getCopy('line2')}</Text>
    </>
  );
};
