import styled from 'styled-components';
import { object, oneOfType, string } from 'prop-types';
import { breakpointStyles, getPropTypes, margin, padding } from './utils';

export const baseStyles = props => ({
  ...padding(props),
  ...margin(props),
  height: props.height,
  maxHeight: props.maxHeight,
  maxWidth: props.maxWidth,
  position: props.position,
  textAlign: props.textAlign,
  width: props.width,
});

export const Base = styled.div<StyleProps>`
  ${baseStyles}
  ${breakpointStyles(baseStyles)};
`;

export const baseProps = {
  height: string,
  margin: oneOfType([string, object]),
  maxHeight: string,
  maxWidth: string,
  padding: oneOfType([string, object]),
  position: string,
  textAlign: string,
  width: string,
};

Base.propTypes = getPropTypes(baseProps);
