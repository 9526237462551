import gql from 'graphql-tag';

export const ScrapeUpdateAccountSettings = gql`
  subscription updateAccountSettingsScrapeFinished(
    $scraperRequestUuid: String!
  ) {
    updateAccountSettingsScrapeFinished(
      scraperRequestUuid: $scraperRequestUuid
    ) {
      success
      scraperRequestUuid
    }
  }
`;

export const ScrapeFailedAccountSettingsMock = {
  updateAccountSettingsScrapeFinished: {
    success: false,
    scraperRequestUuid: 'fake-uuid',
  },
};

export const ScrapePassedAccountSettingsMock = {
  updateAccountSettingsScrapeFinished: {
    success: true,
    scraperRequestUuid: 'fake-uuid',
  },
};
