import { ELEMENT_TYPES, LOCATIONS, ElementMap } from './shared';
// The combination of elementName, elementType, location, module, and pageName (and optionally parentElementName)
// must be unique for each clickable element. Module and pageName get added in trackEvent

export const clickableElementMap: ElementMap = {
  billing: {
    billingDetails: {
      downloadStatement: {
        elementName: 'Download Statement',
        elementType: ELEMENT_TYPES.ROW_BUTTON,
        location: LOCATIONS.BILLING_DETAILS,
      },
    },
  },
  paymentInformation: {
    accountMethods: {
      addPaymentMethod: {
        elementName: 'Add Payment Method',
        elementType: ELEMENT_TYPES.SECONDARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
      },
    },
    planMethods: {
      changePaymentMethod: {
        elementName: 'Change Payment Method',
        elementType: ELEMENT_TYPES.SECONDARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
      },
      addPaymentMethod: {
        elementName: 'Add Payment Method',
        elementType: ELEMENT_TYPES.SECONDARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
      },
    },
    paymentMethod: {
      saveChanges: {
        elementName: 'Save Changes',
        elementType: ELEMENT_TYPES.PRIMARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
      },
      remove: {
        elementName: 'Remove',
        elementType: ELEMENT_TYPES.SECONDARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
      },
    },
    changePaymentMethod: {
      saveChanges: {
        elementName: 'Save Changes',
        elementType: ELEMENT_TYPES.PRIMARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
      },
      addPaymentMethod: {
        elementName: 'Add Payment Method',
        elementType: ELEMENT_TYPES.SECONDARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
      },
    },
    addPaymentModal: {
      addAndContinue: {
        elementName: 'Continue',
        elementType: ELEMENT_TYPES.PRIMARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
        parentElementName: 'Stripe Details Step',
      },
      saveChanges: {
        elementName: 'Save',
        elementType: ELEMENT_TYPES.PRIMARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
        parentElementName: 'Payment Preferences Step',
      },
      done: {
        elementName: 'Done',
        elementType: ELEMENT_TYPES.PRIMARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
        parentElementName: 'Confirmation Step',
      },
      cancel: {
        elementName: 'Cancel',
        elementType: ELEMENT_TYPES.SECONDARY_ACTION_BUTTON,
        location: LOCATIONS.PAYMENT_INFORMATION,
        parentElementName: 'Stripe Details Step',
      },
    },
  },
  planReactivation: {
    elementName: 'Plan Reactivation',
    elementType: ELEMENT_TYPES.ROW_BUTTON,
    location: LOCATIONS.DASHBOARD,
  },
};
