import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  split,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { isBefore } from '@arcadiapower/warbler';
import { trackError } from 'domain/tracking';
import store from 'store';
import { copyFor } from 'config';
import { authKey, EXCHANGE_TOKEN_REQUEST_NAME } from 'contexts/session';
import { loggerLink } from 'utils/apollo-link-logger';
import { logout, SESSION_EXPIRED } from 'utils/logout';

const getAccountNameCopy = copyFor('accountSwitcher');
const API_URL = process.env.ARCADIA_API_URL;

const wsLink = new GraphQLWsLink(
  createClient({
    connectionParams: () => ({
      authentication: `Bearer ${store.get(authKey)}`,
    }),
    url: `${process.env.ARCADIA_WEBSOCKET_URL}/graphql`,
  })
);

const httpLink = new HttpLink({
  credentials: 'include',
  headers: {
    Accept: 'application/json',
  },
  uri: `${API_URL}/graphql`,
});

const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext(async request => {
  const token = store.get(authKey);
  const operationName = request.operationName;
  if (operationName !== EXCHANGE_TOKEN_REQUEST_NAME && !token) {
    await logout({
      message: SESSION_EXPIRED,
      operationName,
      pathname: window.location.pathname,
    });
  }
  const authorization = token ? `Bearer ${token}` : '';
  return { headers: { authorization } };
});

const refreshTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const xAuthToken = operation
      .getContext()
      .response?.headers.get('x-auth-token');
    if (xAuthToken) {
      // missing auth investigation: verify api isn't sending empty refresh token
      if (!xAuthToken.trim()) {
        trackError({
          error: new Error('x-auth-token is empty string with space(s)'),
        });
      } else {
        store.set(authKey, xAuthToken);
      }
    }
    return response;
  });
});

const handleTokenExpiration = async (networkError, operationName) => {
  if (networkError?.statusCode === 401) {
    await logout({
      message: SESSION_EXPIRED,
      operationName,
      pathname: window.location.pathname,
    });
    return true;
  }
};

const parseApolloErrors = graphQLErrors => {
  const messages: string[] = [];
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      messages.push(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  return messages;
};

const errorLink = onError(({ graphQLErrors, operation }) => {
  const messages = parseApolloErrors(graphQLErrors);
  messages.forEach(message => {
    console.log(message); // eslint-disable-line no-console
    trackError({ error: new Error(message), extras: { operation } });
  });
});

const retryLink = new RetryLink({
  attempts: async (count, operation, error) => {
    // don't retry if not authorized
    const tokenExpired = await handleTokenExpiration(
      error,
      operation.operationName
    );
    if (count >= 3 && !!error) {
      trackError({ error, extras: { operation } });
      return false;
    }
    return !!error && !tokenExpired;
  },
});

const linkArray = [retryLink, errorLink, refreshTokenLink, authLink, splitLink];

if (import.meta.env.MODE !== 'production') linkArray.unshift(loggerLink);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Search: {
        merge: true,
      },
      VnmSolarProject: {
        fields: {
          active: {
            read(_, { readField }) {
              const startDate = readField<string | null>('startDate');
              if (!startDate) return null;
              return isBefore(startDate, new Date());
            },
          },
          latitude: {
            read(latitude) {
              return parseFloat(latitude);
            },
          },
          longitude: {
            read(longitude) {
              return parseFloat(longitude);
            },
          },
        },
      },
      Account: {
        fields: {
          name: {
            read(name, { readField }) {
              const accountNumber = readField<number>('accountNumber');
              return (
                name || getAccountNameCopy('namePlaceholder', { accountNumber })
              );
            },
          },
        },
      },
      Plan: {
        fields: {
          productStatuses: {
            merge: true,
          },
        },
      },
    },
  }),
  link: ApolloLink.from(linkArray),
  name: 'Lovebird',
});
