export const solar = {
  heading: 'Solar',
  enrollmentTimeline: {
    available: {
      title: 'Finish setting up your account to enroll in community solar',
      subTitle: 'See what final steps you need to take to go solar.',
    },
    pending: {
      title: 'You’re on the list!',
      subTitle: 'We’re working to find a local solar farm for you.',
    },
    waitlist: {
      pendingActiveTitle: 'We’ll confirm your spot on the local solar farm',
      pendingActiveLiveTitle: 'We’ll confirm your spot on the local solar farm',
      waitlistActiveTitle:
        'Your spot is confirmed for %{project}, which launches in %{season}, %{year}.',
      waitlistActiveLiveTitle: 'Your spot is confirmed for %{project}!',
      subTitleLive:
        'You’ll see your personal energy production and solar credits here once your first solar bill is ready.',
      subTitle:
        'Once the farm is turned on, you’ll be officially enrolled and making an impact!',
    },
    enrolled: {
      title: 'You’ll start contributing solar energy to %{stateAbbreviation}',
    },
    sponsoredSolar: {
      available: {
        title: 'Checking your information',
        subTitle:
          'We’re validating the information you provided to confirm your eligibility for community solar.',
      },
      waitlist: {
        title:
          "We'll confirm your eligibility and find you a spot on a local solar farm.",
      },
      enrolled: {
        title:
          "You'll be part of cleaning up your local power grid by contributing solar energy while saving on your utility bill.",
      },
    },
  },
  energyBreakdown: {
    title: 'Your energy breakdown in your zip code',
    ariaLabel: 'zip code',
    subTitle:
      'The electricity generated in zip code %{zipCode} is a mix of different fuel sources. Community solar farms will deliver more renewable solar energy directly to the power grid!',
    sponsoredSolarSubTitle:
      'The electricity generated in zip code %{zipCode} is a mix of different fuel sources. Community solar farms deliver more renewable solar energy directly to the power grid!',
    stats: {
      renewable: 'Renewable',
      nuclear: 'Nuclear',
      fossilFuel: 'Fossil fuel',
    },
  },
  energyBreakdownChart: {
    label: 'Breakdown',
    energyTypes: {
      biomass: 'Biomass',
      coal: 'Coal',
      gas: 'Gas',
      geothermal: 'Geothermal',
      hydro: 'Hydro',
      nuclear: 'Nuclear',
      oil: 'Oil',
      otherFossil: 'Other',
      solar: 'Solar',
      wind: 'Wind',
      unknown: 'Unknown',
    },
  },
  welcome: {
    title: 'Welcome to your dashboard!',
    sponsoredSolarTitle: 'Your solar status',
    subTitle:
      'You’re on your way to bringing more solar energy to %{state}! Check your status below or read our',
    sponsoredSolarSubTitle:
      'You’re on your way to sunny solar savings and bringing more solar energy to %{state}! Check your status below.',
    altCopy:
      'You’re on your way to bringing more solar energy to %{state}! Read our',
    link: 'guide to community solar',
    statePlaceholder: 'Your state',
  },
  explainer: {
    title: 'What is community solar?',
    subTitle: '4 min read',
    text1:
      'Through community solar programs, residents — renters or homeowners — can support solar energy and save on their power bills by joining local solar farms, with no rooftop installation needed.',
    text2: 'Read more in our quick ',
    text3:
      ' and learn why community solar is such an important tool in the fight for the 100% renewable future ',
    links: {
      guide: 'guide to community solar',
      blog: 'on our blog',
    },
  },
  notifications: {
    pastDue: {
      title:
        'Please pay your <span class="notranslate">%{displayName}</span> balance of %{pastDueAmount} to finish enrolling in community solar. ',
      titleWithPaymentAssistance:
        'Please pay your <span class="notranslate">%{displayName}</span> balance of %{pastDueAmount} to finish enrolling in community solar. If you need help paying this balance, check out your utility’s ',
      paymentAssistanceLink: 'payment assistance programs.',
      link: 'Continue to <span class="notranslate">%{displayName}</span>',
      subtitle: 'Last updated %{days} days ago',
    },
    ineligible: {
      title:
        "Unfortunately, we can't enroll you in community solar at this time. Please check your settings for any notifications or contact our support team if you'd like more details.",
    },
  },
  projectTimeline: {
    title: 'Project timeline',
    enrollment: {
      title: 'Enrollment',
      subTitle:
        'Residents like you reserve spots on the upcoming project through Arcadia. Once enough members have signed on, the site is prepped for construction.',
    },
    construction: {
      title: 'Construction',
      subTitle:
        'Contractors place the racking system and install the solar panels and wiring. Then the local utility inspects the project and gives it the OK.',
    },
    launch: {
      title: 'Project launch: %{launchDate}',
      subTitle:
        'The project launches, clean energy flows into the local power grid, and you get solar credits on your energy bills!',
    },
  },
  projectDetails: {
    graphTitle: 'Energy production by month',
    xLabel: 'Month',
    yLabel: 'kwh produced',
    available: {
      title: 'Support a community solar farm',
      location: '%{state}',
      graphBlurb:
        'Your solar production data will show once you’re on a solar farm.',
    },
    active: {
      location: {
        includesCity: '%{city}, %{state}',
        excludesCity: '%{state}',
      },
      description: {
        onStartDate:
          'Great news — your solar farm just turned on today! All of this energy goes into your local power grid.',
        afterStartDate:
          'Your solar farm has been generating energy since %{date}. All of this energy goes into your local power grid!',
      },
    },
    canceled: {
      title: 'Support a community solar farm',
      location: '%{state}',
      graphBlurb:
        'Your solar production data will show once you’re on a solar farm.',
    },
    enrolled: {
      graphBlurb:
        "Sit tight! We're working on retrieving the solar energy outputs from this solar farm.",
    },
    pending: {
      title: 'Your community solar farm',
      sponsoredSolarTitle: 'Your solar farm',
      location: '%{state}',
      graphBlurb:
        'Your solar production data will show once you’re on a solar farm.',
    },
    waitlist: {
      graphBlurb: 'Your solar production data will show once it’s available.',
    },
    closed: {
      title: 'Your community solar farm',
      location: '%{state}',
      graphBlurb: 'Since your plan is inactive, you are not on a solar farm.',
    },
    pastDue: {
      title: 'Your community solar farm',
      location: '%{state}',
      graphBlurb:
        'Your solar production data will show once you’re on a solar farm.',
    },
    statistics: {
      homesPoweredAnnually: 'Estimated homes powered annually',
      mwhProducedAnnually: 'MWh produced annually',
      mwhTooltipLabel: 'megawatt-hour definition',
      mwhTooltip:
        'This is how much solar energy in megawatt-hours (MWh) your project produces. 1 MWh = the amount of energy a one- megawatt generator produces in an hour. It’s equal to 1000 kWh.',
    },
    mdIdentifier: {
      labelledSON: 'SON: %{mdIdentifier}',
      tooltipContent:
        "This is the Service Organization Number, which is Maryland's official identifier for your solar farm.",
      tooltipLabel: 'SON definition',
    },
  },
  personalEnergyProduction: {
    pending: {
      title: 'Your solar energy production',
      body: 'Sit tight! You’ll see how much energy your share of the solar farm is producing once your project is live and your first bill is ready.',
      sponsoredSolarBody:
        'Sit tight! Once your share of the solar farm starts producing energy, that’s when you can expect to see savings from Arcadia.',
      emptyPlaceholder: '—',
      kwhLabel: 'kWh produced this period',
      solarCreditsLabel: 'solar credits on your bill',
    },
    waitlist: {
      title: 'Your solar energy production',
      body: 'Sit tight! You’ll see how much energy your share of the solar farm is producing once your project is live and your first solar bill is ready.',
      liveBody:
        'Sit tight! Once your share of the solar farm starts producing energy, that’s when you can expect to see solar credits on your Arcadia statement.',
      sponsoredSolarLiveBody:
        'Sit tight! Once your share of the solar farm starts producing energy, that’s when you can expect to see savings from Arcadia.',
      emptyPlaceholder: '—',
      kwhLabel: 'kWh produced this period',
      solarCreditsLabel: 'solar credits on your bill',
      allocation: {
        size: '%{calculatedAllocationKw} kW',
        label: 'your allocation size',
        tooltip: {
          copy: 'This is the portion of the Solar Farm that is assigned to you.',
          label: 'Learn more about allocation size',
        },
      },
    },
    enrolled: {
      title: 'Your solar energy production',
      body: 'Your share of the solar farm created energy from <b>%{startDate}</b> to <b>%{endDate}</b>. You received a solar credit on your bill for helping to create clean energy!',
      bodyWithoutCredits:
        'Your share of the solar farm created energy from <b>%{startDate}</b> to <b>%{endDate}</b>.',
      emptyPlaceholder: '—',
      kwhLabel: 'kWh produced this period',
      solarCreditsLabel: 'solar credits on your bill',
      cumulativeSavingsLabel: 'cumulative solar savings',
      allocation: {
        size: '%{calculatedAllocationKw} kW',
        label: 'your allocation size',
        tooltip: {
          copy: 'This is the portion of the Solar Farm that is assigned to you.',
          label: 'Learn more about allocation size',
        },
      },
    },
    closed: {
      title: 'Your solar energy production',
      body: 'This plan is inactive.',
      emptyPlaceholder: '—',
      kwhLabel: 'kWh produced this period',
      solarCreditsLabel: 'solar credits on your bill',
      cumulativeSavingsLabel: 'cumulative solar savings',
    },
  },
  stateStats: {
    statsMap: {
      legend: {
        title: 'Solar farms',
        live: 'Live',
        comingSoon: 'Coming soon',
        yourFarm: 'Your farm',
      },
      projectStarted: 'Live since %{date}',
      projectPending: 'Live on %{date}',
      invisibleTable: {
        title: 'Solar farms in your state',
        farmName: 'Farm Name',
        kwh: 'Kilowatt Hours Generated',
        startDate: 'Start Date',
        yourFarm: 'Your farm: <span class="notranslate">%{name}</span>',
      },
    },
    title: 'Solar farms in <span class="notranslate">%{stateFullName}</span>',
    subTitle:
      'Explore the solar farms we partner with in <span class="notranslate">%{stateFullName}</span> as we work to replace dirty fossil fuels with solar energy.',
    stats: {
      solarMembers: 'Solar members',
      solarFarms: 'Solar farms',
      expectedMWh: 'Expected MWh',
      mwhTooltipLabel: 'megawatt-hour definition',
      mwhTooltip:
        'This is how much solar energy in megawatt-hours (MWh) <span class="notranslate">%{stateFullName}’s</span> projects are expected to produce. 1 MWh = the amount of energy a one-megawatt generator produces in an hour. It’s equal to 1000 kWh',
    },
  },
  finishSetupCta: {
    title: 'To do: set up your account',
    subTitle:
      'Once everything’s finished, you can help bring solar energy to <span class="notranslate">%{stateName}</span>. Just complete a few more steps!',
    button: 'Finish setting up your account',
    statePlaceholder: 'Your state',
  },
  voiceSupportCard: {
    nonDefault: {
      title: 'Voice support for clean energy in State!',
      paragraph: {
        under10:
          'Members like you have emailed their representatives. Join us by emailing your reps — we’ve made it easy for you!',
        over9:
          'Members like you in %{stateAbbreviation} have sent <b>%{numberOfSubmissions} emails</b> and counting to representatives. Join us by emailing your reps — we’ve made it easy for you!',
      },
    },
    default: {
      title: 'Want community solar in %{state}? Let your legislators know.',
      paragraph: {
        under10:
          'Join the members who’ve already advocated for solar! We’ll help you email your representatives.',
        over9:
          'Join the <b>%{numberOfSubmissions} members</b> and counting in %{stateAbbreviation} who’ve already advocated for solar! We’ll help you email your representatives.',
      },
      alt: 'solar panels',
    },
    link: 'Send an email',
    waitlist: {
      title: 'Voice support for clean energy in State!',
      paragraph: {
        default:
          'New legislation in your state would expand access to local community solar for %{state} residents. Join us in supporting this bill!',
      },
    },
    waitlistLink: 'Contact your representatives',
  },
  submissionCard: {
    default: {
      title: 'Thanks for advocating for community solar!',
      description: {
        under10: 'Get your friends and family to voice their support, too.',
        over9:
          '<b>%{numberOfSubmissions} members</b> and counting have advocated for community solar in <span class="notranslate">%{stateFullName}</span>! Get your friends and family to voice their support, too.',
      },
      shareCopy: 'Share this action with your %{stateAbbreviation} community:',
    },
    nonDefault: {
      title: 'Thanks for supporting a clean energy bill!',
      description: {
        under10:
          'Get your friends and family to voice their support by sharing the bill with them.',
        over9:
          'We’ve sent <b>%{numberOfSubmissions} emails</b> and counting to %{stateAbbreviation} representatives! Get your friends and family to voice their support by sharing the bill with them.',
      },
      shareCopy: 'Share this bill with your %{stateAbbreviation} community:',
    },
  },
  advocacyShare: {
    ctas: {
      email: 'Email',
      facebook: 'Share',
      twitter: 'Tweet',
    },
  },
  completedCampaignCard: {
    description: '%{message}',
    shareCopy: 'Share clean energy with your friends:',
    socialShareCopy:
      'This legislation just passed to expand community solar in my state!',
  },
  canceledStatus: {
    title: 'Your solar status',
    copy: 'You’re no longer enrolled in a community solar project. Please contact our support team if you’d like to re-enroll.',
    buttons: {
      support: 'Contact support',
    },
  },
  rewardsWidget: {
    title: 'Your Arcadia Rewards',
    redirectToRewardsDashboard: 'View my rewards',
    daysToReward: 'Days to next reward',
    nextReward: 'Next reward',
    rewardBalance: 'Reward balance',
    pendingMessage:
      'We are confirming your eligibility for Community Solar. Once verified, you’ll start earning rewards every month!',
  },
};
