import { createGlobalStyle } from 'styled-components';
import { ShrikeTheme } from '@arcadiapower/shrike';
import { zIndexLocalizeWidget } from 'domain/style';

export const GlobalStyle = createGlobalStyle<{ theme: ShrikeTheme }>`
  * {
    box-sizing: border-box;
  }

  &&& {
    [data-js-focus-visible] :focus:not([data-focus-visible-added]) {
      outline: none;
      border-color: none;
      box-shadow: none;
    }
  }

  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  b {
    font-family: ${props => props.theme.fontFamilies.main.medium};
  }

  html, body, #root {
    margin: 0;
    && {
      padding: 0;
    }
    width: 100%;
  }

  #onetrust-banner-sdk, #onetrust-consent-sdk {
    font-family: ${props => props.theme.fontFamilies.main.regular};
  }
  #localize-widget {
    z-index: ${zIndexLocalizeWidget};
  }
`;
