import styled from 'styled-components';

export const AppContent = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.navOffset};
  min-height: ${props => `calc(100vh - ${props.navOffset})`};
  height: ${props => `calc(100vh - ${props.navOffset})`};
  overflow: auto;
  background: ${props =>
    props.usePrimaryBackgroundColor
      ? props.theme.colors.background.primary
      : props.theme.colors.background.secondary};
`;
