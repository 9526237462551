import React from 'react';
import { Link } from './nav-link.style';

interface Props {
  ariaLabel?: string;
  children: React.ReactNode;
  path: string;
  padding?: string;
  margin?: Margin;
  onClick?: () => void;
  textHover?: boolean;
}

export const NavLink = (props: Props) => {
  const {
    ariaLabel,
    children,
    path,
    padding,
    onClick,
    margin = '8px 0',
    textHover = true,
    ...rest
  } = props;
  return (
    <Link
      to={path}
      onClick={onClick}
      aria-label={ariaLabel}
      padding={padding}
      margin={margin}
      textHover={textHover}
      {...rest}
    >
      {children}
    </Link>
  );
};
