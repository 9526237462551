import { routes } from 'config';

type AccountSettingsInfoCredentials =
  GetAccountSettingsInfoQuery['account']['utilityCredentials'];

type AccountSettingsInfoPaymentMethods =
  GetAccountSettingsInfoQuery['account']['paymentMethods'];

export const getUniqueCredentialIds = (
  utilityCredentials: AccountSettingsInfoCredentials
): number[] => {
  return [...new Set(utilityCredentials.map(({ id }) => id))];
};

export const getUniquePaymentMethodIds = (
  paymentMethods: AccountSettingsInfoPaymentMethods
): number[] => {
  return [...new Set(paymentMethods.map(({ id }) => id))];
};

export const getNumberOfUtilities = (
  utilityCredentials: AccountSettingsInfoCredentials
): number => {
  const uniqueCredentialIds = getUniqueCredentialIds(utilityCredentials);
  return uniqueCredentialIds.length;
};

export const getNumberOfPaymentMethods = (
  paymentMethods: AccountSettingsInfoPaymentMethods
): number => {
  const uniquePaymentMethodIds = getUniquePaymentMethodIds(paymentMethods);
  return uniquePaymentMethodIds.length;
};

export const getUtilitySyncRoute = (
  utilityCredentials: AccountSettingsInfoCredentials
) => {
  const credentialIds = getUniqueCredentialIds(utilityCredentials);
  return credentialIds.length === 1
    ? routes({ utilityCredentialId: credentialIds[0] }).utilitySyncCredential
    : routes().utilitySync;
};

export const getLatestUtilityIdSyncRoute = (id: number) => {
  return id
    ? routes({ utilityCredentialId: id }).utilitySyncCredential
    : routes().utilitySync;
};
