import { billing } from './billing';
import { emptyState } from './empty-state';
import { modules } from './modules';
import { cta } from './cta';
import { usage } from './usage';
import { settings } from './settings';
import { solar } from './solar';
import { dashboard } from './dashboard';
import { notifications } from './notifications';
import { advocacy } from './advocacy';

const mainCopy = {
  accountSwitcher: {
    closedLabel: ' (closed)',
    dropdownLabel: 'Switch account',
    button: 'View %{accountLabel}',
    namePlaceholder: '#%{accountNumber}',
  },
  planSwitcher: {
    closedLabel: ' (inactive)',
    dropdownLabel: 'Select plan',
    loading: 'Loading your plans',
    list: {
      button: 'View %{planLabel}',
    },
  },
  planSwitcherLite: {
    defaultName: 'Plan #%{id}',
  },
  navigation: {
    mobileNav: {
      dashboard: 'Dashboard',
      menu: 'Menu',
      notifications: 'Notifications',
    },
    sideNav: {
      dashboard: 'Dashboard',
      signout: 'Sign out',
      settings: 'Settings',
      close: 'Close',
      help: 'Help & FAQ',
    },
    subNav: {
      usage: 'Usage',
      billing: 'Billing',
      solar: 'Solar',
    },
    navBar: {
      links: {
        advocacy: 'Advocacy',
        dashboard: 'Dashboard',
        notifications: 'Notifications',
        usage: 'Usage',
        solar: 'Solar',
        billing: 'Bill',
        profile: 'Profile',
        logout: 'Logout',
        settings: 'Settings',
      },
      menu: {
        settings: 'Settings',
        logout: 'Sign out',
        help: 'Help & FAQ',
        switchAccount: 'Switch account',
      },
      aria: {
        switchAccount: 'Switch account from %{accountLabel}',
        selectedAccount: 'Currently viewing: %{accountLabel}',
        menu: 'Menu',
        dashboard: 'Dashboard',
        preference: {
          label: 'Language preference',
          en: 'English',
          es: 'Español',
          toast: 'Your Language preference has been changed',
          error:
            'Something went wrong. Email support@arcadia.com or call 866-526-0083, M-F, 9-5 PM EST for support.',
        },
        notification: 'You have a notification',
        newNotifications:
          'Notifications. You have %{length} new notifications.',
      },
    },
    backLink: {
      link: 'Back',
      ariaLabel: 'Back to %{location}',
      dashboardLocations: {
        usage: 'usage page',
        energyAnalysis: 'energy analysis page',
        billing: 'billing page',
        statements: 'billing history page',
        solar: 'solar page',
        advocacyAddress: 'address page',
        dashboard: 'dashboard',
      },
      locations: {
        '/settings': 'settings page',
        '/settings/personal-information': 'personal information page',
        '/settings/membership': 'membership page',
        '/settings/membership/cancel': 'cancel page',
        '/settings/utility-sync': 'utility sync page',
        '/settings/payment-methods': 'payment information page',
        '/settings/payment-methods/wallet': 'wallet',
        '/settings/payment-method/settings': 'payment preferences',
      },
    },
    backButton: {
      button: 'Back',
      ariaLabel: 'Back to %{location}',
      locations: {
        '/settings': 'settings',
        '/settings/moving': 'moving',
        '/settings/moving/select-account': 'moving - select account page',
        '/settings/moving/add-account-details':
          'moving - add account details page',
        '/settings/moving/add-utility-service-details':
          'moving - add utility service details page',
        '/settings/moving/set-up-service': 'moving - set up service page',
        '/settings/moving/get-setup-reminder':
          'moving - get setup reminder page',
        '/settings/moving/add-online-account':
          'moving - add online account page',
        '/settings/moving/get-online-setup-reminder':
          'moving - get online setup reminder page',
        '/settings/moving/view-confirmation': 'moving - view confirmation page',
      },
    },
    tabTitlePrefix: 'Arcadia | ',
  },
  headings: {
    solarTitle: 'Solar',
    billingTitle: 'Billing',
    usageTitle: 'Usage',
    subTitleWithNickname:
      'Viewing <span class="notranslate">%{nickname}: %{address}</span>',
    subTitle: 'Viewing <span class="notranslate">%{address}</span>',
  },
  shared: {
    location: '%{city}, %{state}',
  },
  closed: {
    banner: 'This plan is inactive.',
    reactivate: 'Reactivate plan',
  },
  loading: {
    title: 'Loading, please wait',
  },
  error: {
    headerText: 'An error occurred',
    bodyText:
      'Something went wrong on our end, and we’re fixing it. Refresh or try again later.',
    warningIconLabel: 'An error occurred',
  },
  carouselButtons: {
    previous: 'Previous %{itemName}',
    next: 'Next %{itemName}',
    inner: '%{index} of %{length}',
  },
  buttons: {
    edit: 'Edit',
    cancel: 'Cancel',
    closeModal: 'Close modal',
    delete: 'Delete',
    save: 'Save',
  },
  dropdown: {
    placeholder: 'Select an option',
  },
  errors: {
    client: {
      advocacyAddressWrongState:
        'We’re sorry! That address is not in %{stateFullName}.',
      emailTaken: 'That email already exists in our system.',
      invalidEmail: 'Please enter a valid email.',
      firstNameBlank: 'First name cannot be blank.',
      lastNameBlank: 'Last name cannot be blank.',
      invalidPassword:
        'Password must contain 8 or more characters and include at least one number',
      invalidZipCode: 'Zip code is not valid.',
    },
    server: {
      generic:
        'That didn’t work. Please try again or contact support@arcadia.com.',
      emailNotFound: 'We do not have that email address on file.',
      emailEmpty: 'Email cannot be empty.',
      emailDuplicate: 'That email already exists in our system.',
      invalidAddress: 'Please enter a valid address',
      phoneNumberLength: 'Please enter a valid phone number.',
      zipCodeInvalid: 'Please enter a valid zip code.',
      passwordInvalid: 'Password must include at least 8 characters.',
      paymentMethodDuplicate: 'Payment method already exists.',
      deletingActivePaymentMethod:
        'This payment method is associated with an active account.',
      missingCivicData:
        'We’re sorry! We don’t have information about the representatives for that address.',
      firstNameBlank: 'First name cannot be blank.',
      lastNameBlank: 'Last name cannot be blank.',
    },
  },
  toastNotifications: {
    update: '%{content} updated!',
    utilityCredential: {
      update: 'Thanks! Checking your information now...',
      correct: 'Success! We’ve connected to your utility account.',
      incorrect:
        'That didn’t work. Please try connecting to your utility again.',
    },
    accountSwitched: 'Viewing <span class="notranslate">%{name}</span>',
  },
  utils: {
    // TODO - remove in favor of default i18n-js pluralization
    month: 'month',
    months: 'months',
    listJoiners: ['', ' and ', ', ', ', and '],
    seasons: {
      winter: 'Winter',
      spring: 'Spring',
      summer: 'Summer',
      fall: 'Fall',
    },
  },
  pluralization: {
    months: {
      zero: 'first month',
      one: '1 month',
      other: '%{count} months',
    },
    solarFarms: {
      zero: '0 solar farms',
      one: '1 solar farm',
      other: '%{count} solar farms',
    },
    days: {
      one: '1 day',
      other: '%{count} days',
    },
  },
  social: {
    share: 'Share with %{platform} (opens in new tab)',
  },
  referrals: {
    savings: {
      title: 'Invite friends and save money',
      subtitle:
        'Bring your friends into the clean energy community. Get $10 for every referral who joins.',
    },
    impact: {
      title: 'Invite your friends',
      subtitle:
        'Increase your impact by bringing your friends into the clean energy community.',
    },
    linkHeader: 'Share your link',
    copyAriaLabel: 'Copy your personal referral link',
  },
};

export const copy = {
  ...mainCopy,
  advocacy,
  billing,
  cta,
  dashboard,
  emptyState,
  modules,
  notifications,
  settings,
  solar,
  usage,
};
