import React from 'react';
import ReactDOM from 'react-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LAUNCH_DARKLY_CLIENT_ID } from 'domain/launch-darkly';
import { initialize } from 'domain/tracking';
import { inspect } from '@xstate/inspect';
import { App } from './app';
import '@arcadiapower/shrike/dist/shrike.css';
import 'react-router-modal/css/react-router-modal.css';
import 'focus-visible/dist/focus-visible.js';

// initialize sentry for staging and prod
if (['production', 'staging'].includes(import.meta.env.MODE)) initialize();

if (process.env.ARCADIA_SHOW_STATE_CHARTS) inspect({ iframe: false });

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_ID,
    options: {
      bootstrap: 'localStorage',
    },
  });
  if (['development', 'staging'].includes(import.meta.env.MODE))
    window.Localize?.showWidget();
  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root')
  );
})();
