import { useState, useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { ScrapeUpdateAccountSettings } from './subscription.api';

interface Props {
  onDataCallback: (boolean) => void;
  scrapeId: string;
}

export const UtilityAccountScrape = ({ onDataCallback, scrapeId }: Props) => {
  const [scraperId, setScraperId] = useState(scrapeId);

  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === 'scraperId') {
      const updatedScraperId = localStorage.getItem('scraperId');
      setScraperId(updatedScraperId || '');
    }
  };

  useEffect(() => {
    setScraperId(scrapeId);
    let timeoutId;
    const ONE_HOUR = 60 * 60 * 1000;
    if (scrapeId !== '') {
      localStorage.setItem('scraperId', scrapeId);
      timeoutId = setTimeout(() => {
        removeScraperIdFromLocalStorage();
      }, ONE_HOUR);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [scrapeId]);

  const removeScraperIdFromLocalStorage = () => {
    localStorage.removeItem('scraperId');
    handleStorageChange({ key: 'scraperId' } as StorageEvent);
  };

  useSubscription(ScrapeUpdateAccountSettings, {
    variables: {
      scraperRequestUuid: scraperId ? scraperId.replace(/['"]+/g, '') : '',
    },
    skip: scraperId === '',
    shouldResubscribe: true,
    onData: data => {
      const success =
        data.data?.data?.updateAccountSettingsScrapeFinished?.success;
      onDataCallback(success);
      if (success) removeScraperIdFromLocalStorage();
    },
  });
  return null;
};
