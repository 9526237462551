import styled, { css } from 'styled-components';
import { Icon, Button } from '@arcadiapower/shrike';

export const Wrapper = styled.div`
  width: 100%;

  button {
    border: none;

    &:hover {
      background: inherit;
    }
  }
`;

const expandedContent = css`
  height: auto;
  padding: 0 16px 40px;
  max-height: 9999px;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
`;

export const Content = styled.div<StyleProps>`
  max-height: 0;
  overflow: hidden;
  padding: 0 16px;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  ${props => (props.expanded ? expandedContent : null)}
  ${props => props.contentPadding && `padding: ${props.contentPadding}`}
`;

export const ExpandCollapseIcon = styled(Icon)<StyleProps>`
  transition: transform 0.3s ease;
  transform: rotate(0deg);
  ${props => (props.expanded ? `transform: rotate(-180deg);` : null)}
`;

export const StyledButton = styled(Button).attrs({
  backgroundless: true,
})<StyleProps>`
  ${props => props.padding && `padding: ${props.padding}`}
`;
