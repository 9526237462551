import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { copyFor, routes } from 'config';
import { trackEvent } from 'domain/tracking';
import { Modal, Text, Button, Icon } from '@arcadiapower/shrike';
import { useAccountContext } from 'contexts/account';
import { getLatestUtilityIdSyncRoute } from '../../pages/settings/components/account-settings/account-settings.utils';
import { GET_ACCOUNT_UTILITY_INFO } from './utility-modal.api';
import {
  ModalFooterContainer,
  StyledIconButton,
  ModalWrapper,
} from './utility-modal.styles';

const getCopy = copyFor('dashboard.standardDashboard');

interface Props {
  scraperfailed: boolean;
  onRemoveScraperId: () => void;
}

export const UtilityRedirectModal = (props: Props) => {
  const [utilitySyncRoute, setUtilitySyncRoute] = useState(
    routes().utilitySync
  );
  const { scraperfailed, onRemoveScraperId } = props;
  const history = useHistory();
  const { selectedAccountId } = useAccountContext();
  const [showModal, setShowModal] = useState(scraperfailed);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { data } = useQuery<
    GetAccountSettingsInfoQuery,
    GetAccountSettingsInfoQueryVariables
  >(GET_ACCOUNT_UTILITY_INFO, {
    variables: { accountId: selectedAccountId },
  });

  const { account } = data || {};

  useEffect(() => {
    if (account?.utilityCredentials) {
      const utilityCredentials: any = Object.assign(
        [],
        account?.utilityCredentials
      );
      if (utilityCredentials.length > 1) {
        utilityCredentials.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
      const resyncRoute = getLatestUtilityIdSyncRoute(
        utilityCredentials[0]?.id
      );
      setUtilitySyncRoute(resyncRoute);
    }
  }, [data]);

  useEffect(() => {
    setShowModal(scraperfailed);
    if (scraperfailed) {
      trackEvent('track_resync_pop_up_shown', {
        metadata: { accountId: selectedAccountId },
      });
    }
  }, [scraperfailed]);

  useEffect(() => {
    if (searchParams.get('showUtilityResync') === 'true') {
      setShowModal(true);
      trackEvent('track_resync_pop_up_shown', {
        metadata: { accountId: selectedAccountId },
      });
    }
  }, []);

  const redirectToUtilitySettings = () => {
    localStorage.setItem('utilityModalClosed', 'true');
    onRemoveScraperId();
    history.push({
      pathname: utilitySyncRoute,
      state: { showUtilityResyncPopup: true },
    });
    trackEvent('track_resync_click', {
      metadata: { accountId: selectedAccountId },
    });
  };

  return showModal && !localStorage.getItem('utilityModalClosed') ? (
    <Modal
      aria-label="My modal"
      size="small"
      data-testid="utility-connect-modal"
    >
      <ModalWrapper>
        <Modal.Header title={getCopy('utilitySyncModal.title')} />
        <StyledIconButton
          aria-label={getCopy('utilitySyncModal.close')}
          icon="UIClose"
          onClick={() => {
            setShowModal(false);
            localStorage.setItem('utilityModalClosed', 'true');
            onRemoveScraperId();
            trackEvent('track_resync_pop_up_closed', {
              metadata: { accountId: selectedAccountId },
            });
          }}
        />
        <Text
          margin={{
            bottom: '0',
            left: '32px',
            right: '32px',
            top: '32px',
          }}
          tag="p"
          color="primary"
          opacity="high"
          textStyle="paragraph500"
        >
          {getCopy('utilitySyncModal.msg1')}
        </Text>
        <Text
          margin={{
            bottom: '32px',
            left: '32px',
            right: '32px',
            top: '16px',
          }}
          tag="p"
          color="primary"
          opacity="high"
          textStyle="paragraph500"
        >
          {getCopy('utilitySyncModal.msg2')}
        </Text>
        <ModalFooterContainer>
          <Button
            backgroundColor="primaryInverse"
            onClick={redirectToUtilitySettings}
            size="medium"
            data-testid="utility-modal-submit-button"
            margin={{
              bottom: '0',
              left: '32px',
              right: '32px',
              top: '0',
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Icon
              color="primaryInverse"
              icon="Lock"
              opacity="high"
              path="M 1,3 A 2,2 0,0,1 5,3 A 2,2 0,0,1 9,3 Q 9,6 5,9 Q 1,6 1,3 z"
              scale={2}
              title="Lock"
            />
            <Text
              margin={{
                left: '8px',
              }}
              tag="p"
              color="primaryInverse"
              opacity="high"
              textStyle="heading500"
            >
              {getCopy('utilitySyncModal.ctaText')}
            </Text>
          </Button>
        </ModalFooterContainer>
      </ModalWrapper>
    </Modal>
  ) : null;
};
