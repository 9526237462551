import styled from 'styled-components';
import { Button } from '@arcadiapower/shrike';
import { Container } from 'styles';

export const AvatarWrapper = styled(Container).attrs({
  height: '20px',
  width: '20px',
})<StyleProps>``;

export const NavButton = styled(Button).attrs({
  backgroundless: true,
  margin: { right: '30px' },
})`
  border: none;
  border-radius: 0;
  padding: 20px 8px;
  &:hover {
    background: inherit;
  }
`;
