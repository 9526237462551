import styled from 'styled-components';
import { number, string } from 'prop-types';
import { Base, baseProps } from './base.style';
import { breakpointStyles, getPropTypes } from './utils';

const gridCellStyles = props => ({
  alignSelf: props.alignSelf,
  gridColumn: props.columns ? `auto / span ${props.columns}` : '',
  gridColumnStart: props.columnStart,
  gridRow: props.rows ? `auto / span ${props.rows}` : '',
  justifySelf: props.justifySelf,
});

export const GridCell = styled(Base)<StyleProps>`
  ${gridCellStyles}
  ${breakpointStyles(gridCellStyles)}
`;

export const gridCellProps = {
  ...baseProps,
  alignSelf: string,
  columns: number,
  gridColumnStart: number,
  justifySelf: string,
  rows: number,
};

GridCell.propTypes = getPropTypes(gridCellProps);
