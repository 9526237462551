import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { utils } from 'styles';

export const Link = styled(
  ({
    // Selective prop passing here handles React unknown prop warning
    /* eslint-disable @typescript-eslint/no-unused-vars */
    textHover,
    /* eslint-enable */
    ...props
  }) => <RouterLink {...props} />
)<StyleProps>`
  ${props => utils.margin(props)}
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: ${props => (props.padding ? props.padding : '8px')};
  text-decoration: none;
  border-radius: ${props => props.theme.borderRadii.large};

  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.content.primary};
    outline: transparent solid 2px;
  }

  &:hover {
    p {
      ${props =>
        props.textHover && `color: ${props.theme.colors.content.primary};`}
    }
  }
`;
