import styled, { keyframes } from 'styled-components';
import { Button, IconButton, Icon } from '@arcadiapower/shrike';
import { Modal } from 'react-router-modal';

const animation = keyframes`
  0% { transform: translateX(-100%);}
  100% { transform: translateX(0%); }
`;

export const StyledModal = styled(Modal)<StyleProps>`
  background: ${props => props.theme.colors.background.primary};
  width: 90%;
  position: fixed;
  transform: translateX(0%);
  height: 100%;
  max-height: 100%;
  animation: ${animation} 0.5s;
`;

export const StyledButton = styled(Button).attrs({
  backgroundless: true,
  color: 'primary',
  margin: { top: '8px', bottom: '8px' },
})`
  border: none;
  padding: 8px;
`;

export const CloseButton = styled(IconButton).attrs({
  icon: 'UIClose',
})`
  border: none;
  display: block;
  top: 10px;
  padding: 8px;
  position: absolute;
`;

export const StyledIcon = styled(Icon).attrs({
  margin: { right: '12px' },
  color: 'primary',
})``;
