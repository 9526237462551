export const cta = {
  billPay: {
    button: 'Turn on auto-pay',
    buttonWithoutBillPay: 'Add payment method',
    highlightedText:
      'Your <span class="notranslate">%{utilityName}</span> bill of $%{utilityCharge} is due in %{numberOfDays} days',
    highlightedTextWithoutBillPay:
      'This plan does not have an assigned payment method. Please add a payment method so we can process energy statements for this plan.',
    text: {
      withoutPaymentMethod:
        'Automatically pay your bill every month with no convenience fees.',
      withoutBillPayOrPaymentMethod:
        'Add a payment method so monthly payments can be processed.',
    },
    title:
      'Automatically pay your <span class="notranslate">%{utilityName}</span> bill every month',
    dualBillingTitle:
      'Add a payment method so we can process your contributions to the solar farm',
    utilityFallback: 'utility',
  },
  utilityConnect: {
    button: 'Sync my account',
    highlightedText:
      'This month we purchased %{kwh} clean kWh for you based on <span class="notranslate">%{utility}’s</span> average customer use.',
    title: 'Sync your <span class="notranslate">%{utility}</span> account',
    text: 'Sync your <span class="notranslate">%{utility}</span> account so we can purchase and retire the exact number of EPA RECs to match your usage.',
    utilityFallback: 'utility',
  },
  vnm: {
    button: 'Support a project',
    title: 'Support a solar farm in %{state}.',
    text: 'Use a part of your monthly electricity bill to fund a community solar project. Our systems automate everything, and you’ll actually save a bit of money each month.',
    alt: 'Solar panels',
  },
};
