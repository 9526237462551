import React, { LazyExoticComponent, Suspense } from 'react';

export const withSuspense = (
  Component: LazyExoticComponent<() => JSX.Element | null>,
  props = {}
) => (
  <Suspense fallback={<div />}>
    <Component {...props} />
  </Suspense>
);
