// For an overview of how we use segment, check out the docs here:
// https://github.com/ArcadiaPower/anoctua/blob/master/docs/integrations/analytics_with_segment.md
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { matchPath } from 'react-router-dom';
import { get } from 'lodash-es';
import { routes as routeTemplates } from 'config';
import { clickableElementMap } from './clickable-events';
import { viewableElementMap } from './viewable-events';

// Cookie added in Osprey for arcadia domains
const isAdminUser = document.cookie
  .split('; ')
  .find(row => row.startsWith('isAdminUser='))
  ?.split('=')[1];

export const initialize = (): void => {
  // initialize sentry - needs to run as early in the code as possible
  const env = import.meta.env.MODE;
  Sentry.init({
    dsn: process.env.ARCADIA_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampler: samplingContext => {
      if (
        env === 'production' &&
        samplingContext.transactionContext.op === 'pageload'
      ) {
        return 0.75;
      } else {
        return 0;
      }
    },
    release: import.meta.env.ARCADIA_SENTRY_RELEASE,
    environment: env,
    ignoreErrors: ['Non-Error promise rejection captured with value:'],
    beforeSend(event) {
      return isAdminUser === 'true' ? null : event;
    },
  });
};

const SOCKET_HANG_UP = 'reason: socket hang up';
const ACCESS_CONTROL_ALLOW_ORIGIN =
  'not allowed by Access-Control-Allow-Origin';
const DUPLICATE_CREDIT_CARD =
  'Validation failed: Credit card already exists in our records';
const DUPLICATE_BANK_ACCOUNT =
  'Validation failed: Bank account already exists in our records';

const setSentryFingerprint = (scope: Sentry.Scope, error?: Error) => {
  const errorMessage = error?.message;
  if (errorMessage) {
    if (errorMessage.includes(SOCKET_HANG_UP))
      scope.setFingerprint([SOCKET_HANG_UP]);
    else if (errorMessage.includes(ACCESS_CONTROL_ALLOW_ORIGIN))
      scope.setFingerprint([ACCESS_CONTROL_ALLOW_ORIGIN]);
    else if (errorMessage.includes(DUPLICATE_CREDIT_CARD))
      scope.setFingerprint([DUPLICATE_CREDIT_CARD]);
    else if (errorMessage.includes(DUPLICATE_BANK_ACCOUNT))
      scope.setFingerprint([DUPLICATE_BANK_ACCOUNT]);
    else scope.setFingerprint([errorMessage]);
  }
};

interface Options {
  error?: Error;
  extras?: Parameters<Sentry.Scope['setExtras']>[0];
  tags?: Parameters<Sentry.Scope['setTags']>[0];
}
export const trackError = ({
  error,
  extras = {},
  tags = {},
}: Options = {}): void => {
  Sentry.withScope(scope => {
    scope.setTags(tags);
    scope.setExtras(extras);
    setSentryFingerprint(scope, error);
    Sentry.captureException(error);
  });
};

const identifyForSentry = (id: string) => {
  Sentry.configureScope(scope => {
    scope.setUser({ id });
  });
};

// Clear cached traits from localStorage
// We need this because Lovebird used to store inappropriate data as user traits
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#clearing-traits
const clearCachedIdentityTraits = newTraits => {
  if (!window.analytics?.ready) {
    return;
  }
  window.analytics.ready(() => {
    try {
      window.analytics.user().traits({});
      window.analytics.user().traits(newTraits);
    } catch (e) {
      console.error(e);
      trackError({ error: e });
    }
  });
};

const identifyForSegment = (userId: string, traits = {}) => {
  try {
    clearCachedIdentityTraits(traits);
    if (window.analytics?.identify) {
      window.analytics.identify(userId, traits);
    }
  } catch (e) {
    console.error(e);
    trackError({ error: e });
  }
};

export const identify = (userId?: number, traits = {}): void => {
  const id = `${userId}`;
  identifyForSentry(id);
  identifyForSegment(id, traits);
};

export const getPageName = (pathname: string): string | undefined => {
  const routes = routeTemplates();
  return Object.keys(routes).find(
    routeKey =>
      !!matchPath(pathname, {
        path: routes[routeKey],
        exact: true,
        strict: true,
      })
  );
};

export const getTrimTitle = () => document.title.split('|')[1]?.trim();

export const TRACKING_EVENTS = {
  PAGE_ELEMENT_VIEWED: 'page_element_viewed',
  PAGE_ELEMENT_CLICKED: 'page_element_clicked',
};

export const trackPage = (name?: string, properties?: unknown): void => {
  try {
    if (window.analytics) window.analytics.page(undefined, name, properties);
  } catch (e) {
    console.error(e);
  }
};

export const trackEvent = (
  eventName: string,
  properties = {} as Record<string, unknown>
): void => {
  const metadata = properties?.metadata;
  if (metadata) {
    // stringify metadata so segment won't flatten
    properties.metadata = JSON.stringify(metadata);
  }
  properties.pageName = getPageName(window.location.pathname);
  properties.module = getTrimTitle();

  if (
    import.meta.env.MODE !== 'production' &&
    import.meta.env.MODE !== 'test'
  ) {
    console.log('TRACKING:', eventName, properties);
  }

  try {
    if (window.analytics) window.analytics.track(eventName, properties);
  } catch (e) {
    console.error(e);
  }
};

export const trackClickEvent = (
  elementKey: string,
  properties?: Record<'metadata', unknown>
) => {
  const basicProperties = get(clickableElementMap, elementKey);
  trackEvent(TRACKING_EVENTS.PAGE_ELEMENT_CLICKED, {
    ...basicProperties,
    ...properties,
  });
};

export const trackViewEvent = (
  elementKey: string,
  properties?: Record<'metadata', unknown>
) => {
  const basicProperties = get(viewableElementMap, elementKey);
  trackEvent(TRACKING_EVENTS.PAGE_ELEMENT_VIEWED, {
    ...basicProperties,
    ...properties,
  });
};
