import React, { createContext, useEffect, useState } from 'react';
import store from 'store';
import { useApolloClient, useQuery } from '@apollo/client';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingPage } from 'components/loading';
import { identify } from 'domain/tracking';
import { useAsync } from 'hooks/use-async.hook';
import { logout, LINK_EXPIRED, SESSION_EXPIRED } from 'utils/logout';
import { deriveUserIdFromToken } from './session.utils';
import { SessionContext } from './session.typings';
import { EXCHANGE_TOKEN, GET_FUNNEL_SOURCE } from './session.api';

export const Session = createContext<SessionContext | undefined>(undefined);

export const authKey = 'auth_token';

interface Props {
  children?: React.ReactNode;
}

export const SessionProvider = ({ children }: Props) => {
  const client = useApolloClient();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmCampaign = searchParams.get('utm_campaign');
  const loginToken = searchParams.get('loginToken');
  const showRewardsGuidelines = searchParams.get('showRewardsGuidelines');
  const sponsoredSolar = searchParams.get('sponsoredSolar');
  const storedScraperId = localStorage.getItem('scraperId');
  const scraperId = searchParams.get('scraperId') || storedScraperId || '';
  const ldClient = useLDClient();
  const [userId, setUserId] = useState<number | undefined>();

  if (scraperId !== '') {
    localStorage.setItem('scraperId', scraperId);
  }

  if (showRewardsGuidelines) {
    localStorage.setItem('showRewardsGuidelines', showRewardsGuidelines);
  }

  if (sponsoredSolar) {
    localStorage.setItem('sponsoredSolar', sponsoredSolar);
  }

  if (utmSource) {
    localStorage.setItem('utmSource', utmSource);
  }

  if (utmMedium) {
    localStorage.setItem('utmMedium', utmMedium);
  }

  if (utmCampaign) {
    localStorage.setItem('utmCampaign', utmCampaign);
  }

  // if loginToken query param is present, ignore stored auth token
  const [authenticated, setAuthenticated] = useState<boolean>(
    !loginToken && !!store.get(authKey)
  );
  // Prevent requests from being sent with an expired JWT when we have a new loginToken to exchange
  if (loginToken && !authenticated) store.remove(authKey);

  const exchangeToken = async (loginToken: string): Promise<string> => {
    store.remove(authKey);
    const { data } = await client.query({
      query: EXCHANGE_TOKEN,
      variables: { loginToken },
    });
    const token = data?.tokenExchange.token;
    setAuthenticated(!!token);
    store.set(authKey, token);
    return token;
  };

  const setUserWithToken = async (token: string): Promise<void> => {
    const userIdFromToken = deriveUserIdFromToken(token);
    // identify user with launch darkly before setting userId in state to ensure flags are set before rendering
    await ldClient?.identify({ key: userIdFromToken?.toString() });
    setUserId(userIdFromToken);
  };

  const [{ error, loading }, startSession] = useAsync(async loginToken => {
    const token = await exchangeToken(loginToken);
    setUserWithToken(token);
    if (pathname) history.push(pathname);
  });

  const [{ error: rehydrateError }, rehydrateSession] = useAsync(async () => {
    const token = store.get(authKey);
    setUserWithToken(token);
  });

  // if authenticated on mount, fetch user data
  // if login token on mount, start session
  // else logout due to the user being unauthenticated
  useEffect(() => {
    if (authenticated) {
      rehydrateSession();
    } else if (loginToken) {
      startSession(loginToken);
    } else {
      logout({ pathname });
    }
  }, []);

  const waitlist: FunnelTrack = 'waitlist';
  const [isWaitlist, setIsWaitlist] = useState(false);
  const { data } = useQuery(GET_FUNNEL_SOURCE, {
    variables: { userId },
    skip: !userId,
  });
  useEffect(() => {
    if (data?.originationSource?.funnel === waitlist) {
      setIsWaitlist(true);
    }
  }, [data]);

  useEffect(() => {
    if (userId) {
      const properties = isWaitlist ? { funnelTrack: waitlist } : {};
      identify(userId, properties);
    }
  }, [isWaitlist, userId]);

  let value: SessionContext | null = null;
  if (userId) {
    value = {
      userId,
    };
  }

  // TODO: handle errors more gracefully, we are assuming all errors are auth related here
  if (error || rehydrateError)
    logout({ message: error ? LINK_EXPIRED : SESSION_EXPIRED, pathname });
  if (loading || !authenticated || !value) return <LoadingPage />;

  return <Session.Provider value={value}>{children}</Session.Provider>;
};
