import React from 'react';
import { StyledFieldset, StyledLegend } from './language-pref.style';

interface Props {
  children?: React.ReactNode;
  legend?: string;
}

export const RadioGroup = ({ legend, children }: Props) => {
  return (
    <StyledFieldset>
      <StyledLegend fontSize={500} variant="primary">
        {legend}
      </StyledLegend>
      {children}
    </StyledFieldset>
  );
};
