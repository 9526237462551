import styled from 'styled-components';
import { Container } from 'styles';

export const Circle = styled(Container).attrs({
  alignItems: 'center',
  flex: true,
  justifyContent: 'center',
})<StyleProps>`
  position: absolute;
  top: -8px;
  right: -8px;
  height: 16px;
  width: 16px;
  background: ${props =>
    props.someHighPriority
      ? props.theme.colors.content.error
      : props.theme.colors.content.accent4};
  border-radius: ${props => props.theme.borderRadii.circular};

  p {
    font-size: ${props => props.fontSize};
  }
`;

export const Wrapper = styled(Container).attrs({ flex: true })<StyleProps>`
  margin: ${props => props.margin || 0};
  position: relative;
  width: 16px;
  height: 16px;
`;
