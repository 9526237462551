import styled, { css, keyframes } from 'styled-components';
import { theme } from 'config';
import { oneOf } from 'prop-types';
import { breakpointStyles, getPropTypes } from './utils';
import { Base, baseProps } from './base.style';

const variants = ['secondary', 'tertiary'];

const gradientAnimation = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const getShimmerValue = props =>
  props.variant === 'secondary'
    ? props.theme.colors.background.primary
    : props.theme.colors.background.secondary;

const skeletonStyles = props => ({
  background: props.theme.colors.background[props.variant],
  borderRadius: props.borderRadius,
  opacity: props.theme.opacities[props.opacity],
});

const afterStyles = props => {
  if (props.withShimmer) {
    return css`
      animation-name: ${gradientAnimation};
      animation-duration 1.5s;
      animation-iteration-count: infinite;
    `;
  } else if (props.withShimmer === false) {
    return css`
      animation: none;
    `;
  }
};

export const Skeleton = styled(Base)`
  position: relative;
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadii.small};

  ${skeletonStyles}
  ${breakpointStyles(skeletonStyles)}

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      ${getShimmerValue},
      transparent
    );

    ${afterStyles}
    ${breakpointStyles(afterStyles)}
  }
`;

Skeleton.propTypes = getPropTypes({
  ...baseProps,
  opacity: oneOf(Object.keys(theme.opacities)),
  variant: oneOf(variants),
});
