import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { isInactive } from 'domain/plans';

export interface DisplayData {
  displayBilling?: boolean;
  displayUsage?: boolean;
}
interface Props {
  planId?: number;
}
interface DisplayBillingProps {
  status?: string;
  hasMostRecentStatement: boolean;
  vnmEligible?: boolean;
  vnmStatus?: string;
}

type cleanKwh = {
  id: number;
} | null;
interface DisplayUsageProps {
  cleanKilowattHours: cleanKwh[] | undefined;
}

export const getDisplayBilling = ({
  status,
  hasMostRecentStatement,
  vnmEligible,
}: DisplayBillingProps): boolean | undefined => {
  // Always display the billing page for customers in VNM markets.
  // Hide the billing page from closed accounts without statements. The mostRecentStatement
  // function in Gryphon returns the most recent issued statement so if it is null
  // then we can assume that this person has no issued statements.
  if (isInactive(status || '') && !hasMostRecentStatement) {
    return false;
  } else if (hasMostRecentStatement) {
    return true;
  } else return !!vnmEligible;
};

export const getDisplayUsage = ({
  cleanKilowattHours,
}: DisplayUsageProps): boolean => {
  return !!cleanKilowattHours?.length;
};

export const usePageDisplay = ({ planId }: Props) => {
  const { data, loading, error } = useQuery<
    FetchPageDisplayQuery,
    FetchPageDisplayQueryVariables
  >(FETCH_PAGE_DISPLAY_QUERY, {
    skip: !planId,
    variables: { planId: planId || -1 },
  });
  const { cleanKilowattHours, mostRecentStatement, status, utilityAccounts } =
    data?.plan || {};
  const vnmEligible = utilityAccounts?.some(ua => ua.utility.vnmEligible);
  let displayData: DisplayData = {};

  const displayUsage = getDisplayUsage({ cleanKilowattHours });
  const displayBilling = getDisplayBilling({
    hasMostRecentStatement: mostRecentStatement !== null,
    status,
    vnmEligible,
  });

  if (data) {
    displayData = {
      displayBilling,
      displayUsage,
    };
  }

  return {
    data: displayData,
    error,
    loading,
  } as const;
};

export const FETCH_PAGE_DISPLAY_QUERY = gql`
  query FetchPageDisplay($planId: Int!) {
    plan(id: $planId) {
      id
      status
      cleanKilowattHours {
        id
      }
      statements {
        id
      }
      mostRecentStatement {
        id
      }
      utilityAccounts {
        utility {
          vnmEligible
        }
      }
    }
  }
`;

export const fetchPageDisplayMock = {
  plan: {
    cleanKilowattHours: [
      {
        id: 10,
      },
      {
        id: 11,
      },
      {
        id: 12,
      },
    ],
    id: 1,
    mostRecentStatement: {
      id: 1,
    },
    statements: [
      {
        id: 1,
      },
      {
        id: 2,
      },
    ],
    status: 'active',
    utilityAccounts: [
      {
        utility: {
          vnmEligible: true,
        },
      },
    ],
  },
};

export const usePageDisplayMock = {
  data: {
    displayBilling: true,
    displayUsage: true,
  },
  error: undefined,
  loading: false,
};
