import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Logo } from '@arcadiapower/shrike';
import { Container, Grid, GridCell } from 'styles';
import { copyFor, routes } from 'config';
import { NAV_HEIGHT, MAX_NAV_WIDTH } from 'domain/style';
import { SupportModal } from 'components/modals/support';
import { isActive, getNavlinkColor } from '../navigation.utils';
import { NavLinkTab } from '../nav-link-tab';
import { NavLink } from '../nav-link';
import { NotificationIconLink } from '../notification-icon-link';
import { DesktopWrapper } from './desktop-nav.style';
import { Menu } from './menu/menu.component';
import { Preferences } from './preferences/preferences.component';

const getCopy = copyFor('navigation.navBar');
const { dashboard, notifications } = routes();
const linkPadding = '20px 8px';

export const DesktopNav = () => {
  const { pathname } = useLocation();
  const iconColor = getNavlinkColor(isActive(pathname, notifications));
  const [showSupportModal, setShowSupportModal] = useState(false);

  return (
    <>
      <DesktopWrapper data-testid="desktop-nav">
        <Grid maxWidth={MAX_NAV_WIDTH} margin="auto" height={NAV_HEIGHT}>
          <GridCell columnStart={2} height="100%">
            <Container flex alignItems="flex-end" height="100%">
              <NavLink
                path={dashboard}
                padding="10px 8px"
                margin={{ bottom: '2px', top: '8px' }}
                ariaLabel={getCopy('aria.dashboard')}
              >
                <Logo type="circle" aria-hidden={true} />
              </NavLink>
            </Container>
          </GridCell>
          <GridCell height="100%" justifySelf="end" columnStart={8} columns={4}>
            <Container flex height="100%" alignItems="flex-end">
              <Preferences />
              <NavLinkTab
                path={dashboard}
                copy={getCopy('links.dashboard')}
                padding={linkPadding}
                margin={{ right: '20px' }}
              />
              <NotificationIconLink type="desktop" iconColor={iconColor} />
              <Menu openSupportModal={() => setShowSupportModal(true)} />
            </Container>
          </GridCell>
        </Grid>
      </DesktopWrapper>
      {showSupportModal && (
        <SupportModal onClose={() => setShowSupportModal(false)} />
      )}
    </>
  );
};
