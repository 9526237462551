import React from 'react';
import { BackLink as Link } from '@arcadiapower/shrike';
import { copyFor, config } from 'config';
import { MAX_NAV_WIDTH } from 'domain/style';
import { Grid, GridCell, Container } from 'styles';

const getCopy = copyFor('navigation.backLink');

interface To {
  pathname: string;
  state: {
    previouslySelectedUtilityAccount: UtilityAccount;
  };
}

interface Props {
  location?: string;
  to: string | To;
}

export const BackLink = ({ location, to }: Props) => {
  if (!location) {
    if (typeof to === 'string')
      location = getCopy(
        `locations.${
          to as keyof typeof config.copy.navigation.backLink.locations
        }`
      );
    else
      location = getCopy(
        `locations.${
          to?.pathname as keyof typeof config.copy.navigation.backLink.locations
        }`
      );
  }
  return (
    <Grid maxWidth={MAX_NAV_WIDTH} margin="auto" width="100%">
      <GridCell columnStart={2} height="100%" small={{ columnStart: 1 }}>
        <Container
          margin={{ bottom: '8px', left: '12px', top: '32px' }}
          small={{ margin: { left: '24px', top: '24px' } }}
        >
          <Link
            aria-label={getCopy('ariaLabel', { location })}
            to={to}
            openInNewTab={false}
          >
            {getCopy('link')}
          </Link>
        </Container>
      </GridCell>
    </Grid>
  );
};
