import styled from 'styled-components';
import { NAV_HEIGHT, zIndexDesktopNav } from 'domain/style';

export const DesktopWrapper = styled.nav`
  background: ${props => props.theme.colors.background.primary};
  border-bottom: 1px solid ${props => props.theme.colors.background.tertiary};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${zIndexDesktopNav};

  &,
  > div {
    height: ${NAV_HEIGHT};
  }

  svg {
    cursor: pointer;
  }
`;
