import convertHtmlToReact from '@hedgedoc/html-to-react';
import { get } from 'lodash-es';
import { NestedProperty, ObjectDotNotation } from 'types/general';
import { base } from './base';

const CONFIG_TEMPLATE = process.env.ARCADIA_CONFIG_TEMPLATE;

// This is only exported for testing purposes
export const getConfig = (template?: string): typeof base => {
  switch (template) {
    default:
      return base;
  }
};

export const updateTheme = (config: typeof base) => {
  if (config.theme) {
    return {
      ...config,
      theme: {
        ...config.theme,
      },
    };
  } else {
    return config;
  }
};

export const config = updateTheme(getConfig(CONFIG_TEMPLATE));

// test that parameter contains '<' followed by SOMETHING followed by '>'
const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

// convert string to html if present and replace any variables with values
const convertCopy = (copy?: string, args?: object) => {
  let result = copy;
  if (result && args) {
    // find and replace all %{key} with the value of args[key]
    const matcher = Object.keys(args).reduce((acc, key) => {
      acc += acc ? `|%{${key}}` : `%{${key}}`;
      return acc;
    }, '');
    const regex = new RegExp(matcher, 'g');
    result = result.replace(regex, match => args[match.slice(2, -1)]);
  }
  if (result && isHTML(result)) return convertHtmlToReact(result);
  return result;
};

// recursively convert all strings to html and replace any variables with values
const traverseAndConvert = (copy: string | [] | object, args?: object) => {
  if (Array.isArray(copy)) return copy.map(c => traverseAndConvert(c, args));
  else if (typeof copy === 'object')
    return Object.keys(copy).reduce((acc, key) => {
      acc[key] = traverseAndConvert(copy[key], args);
      return acc;
    }, {});
  return convertCopy(copy, args);
};

/*
  NOTE on the typing of copyFor...
  
  this function was written before this app used typescript
  and it's implementation makes proper typing SUPER complicated (but still worth it)
  there are thousands of invocations across the app so it would be very hard to change the implementation

  example of TScope
  'billing' | 'billing.statement' | 'billing.statement.faq'

  example of TProperty when TScope == 'billing'
  'statement' | 'statement.faq'

  ObjectDotNotation<AllCopy>> contains every possible object path in the copy object
  in dot notation

  NestedProperty<AllCopy, SomeDotNotationPath> translates the dot notation path
  into the real object value 

  // TODO: maybe type args to Record<string, string | number>
*/

type AllCopy = typeof base.copy;
type AllCopyPaths = ObjectDotNotation<AllCopy>;
type AllScopedCopyPaths<TScope extends AllCopyPaths> = ObjectDotNotation<
  NestedProperty<AllCopy, TScope>
>;

export const copyFor =
  <TScope extends AllCopyPaths>(scope: TScope) =>
  <TProperty extends AllScopedCopyPaths<TScope>>(
    property: TProperty,
    args?: object
  ) => {
    const scoped: NestedProperty<AllCopy, TScope> = get(
      config.copy,
      scope,
      config.copy
    );
    const copy: NestedProperty<
      typeof scoped,
      TProperty extends string ? TProperty : never
    > = get(scoped, property);

    const result: typeof copy = traverseAndConvert(copy as string, args);
    return result;
  };

export const theme = config.theme;
export const routes = config.routes;
