import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Logo, Icon } from '@arcadiapower/shrike';
import { routes, copyFor } from 'config';
import { SupportModal } from 'components/modals/support';
import { NavLink } from '../nav-link';
import { SideNav } from './side-nav';
import { NotificationIconLink } from '../notification-icon-link';
import { isActive, getNavlinkColor } from '../navigation.utils';
import { Nav, StyledButton, MobileWrapper } from './mobile-nav.style';

const { dashboard, notifications } = routes();
const getCopy = copyFor('navigation.mobileNav');

export const MobileNav = () => {
  const [showSideNav, setShowSideNav] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const { pathname } = useLocation();
  const color = getNavlinkColor(isActive(pathname, notifications));

  return (
    <>
      {showSideNav && (
        <SideNav
          onClose={() => setShowSideNav(false)}
          openSupportModal={() => setShowSupportModal(true)}
        />
      )}
      {showSupportModal && (
        <SupportModal onClose={() => setShowSupportModal(false)} />
      )}
      <Nav data-testid="mobile-nav">
        <MobileWrapper>
          <StyledButton
            aria-label={getCopy('menu')}
            onClick={() => setShowSideNav(true)}
          >
            <Icon icon="UIHamburgerMenu" scale={3} />
          </StyledButton>
          <NavLink path={dashboard} ariaLabel={getCopy('dashboard')}>
            <Logo type="name" scale={2.5} variant="dark" />
          </NavLink>
          <NotificationIconLink iconColor={color} />
        </MobileWrapper>
      </Nav>
    </>
  );
};
