import styled from 'styled-components';
import { Link, Modal } from '@arcadiapower/shrike';

export const SupportModal = styled(Modal)`
  text-align: center;
  h2 {
    margin-left: 56px;
  }
`;

export const SupportModalHeader = styled(Modal.HeaderCentered)`
  margin-left: 24px;
  padding: 0 16px 0px;
`;

export const StyledLink = styled(Link).attrs({ type: 'inline' })`
  font-family: ${props => props.theme.fontFamilies.main.regular};
`;

export const Container = styled.div`
  text-align: center;
`;

export const PrimaryContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 24px;
  padding: 0 16px 0px;
`;

export const SupportTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 30px;
  gap: 16px;
  text-align: left;
`;

export const SupportContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 16px 0px;
`;

export const MxHeadBubble = styled.img.attrs({ alt: '' })<StyleProps>`
  border-radius: 50%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  height: 48px;
  width: 48px;
  z-index: ${props => props.zIndex};
`;

export const MxHeadBubbleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;

  ${MxHeadBubble} ~ ${MxHeadBubble} {
    margin-left: -4px;
  }
`;
