import React from 'react';
import styled from 'styled-components';
import { utils, Container } from 'styles';

export const StyledContainer = styled(
  ({
    /* eslint-enable */
    ...props
  }) => <Container {...props} />
)<StyleProps>`
  ${props => utils.margin(props)}
  display: inline-flex;
  align-items: start;
  justify-content: center;
  outline: none;
  border-radius: ${props => props.theme.borderRadii.large};
`;
