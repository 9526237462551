/* eslint-disable max-classes-per-file */
import { copyFor } from 'config';

const getClientError = copyFor('errors.client');

export class ClientError extends Error {
  type: string;

  constructor(message: string) {
    super(message);
    this.type = 'client';
  }
}

// Use Object.setPrototypeOf(this, ClientError.prototype) for all errors that extend ClientError
// typescript/babel work around for using instanceof operator in utils/errors/translate-error; see https://stackoverflow.com/questions/42064466/instanceof-using-es6-class-inheritance-chain-doesnt-work
// see utils/errors/translate-error line 10

export class AdvocacyAddressWrongStateError extends ClientError {
  constructor(stateFullName?: string) {
    super(getClientError('advocacyAddressWrongState', { stateFullName }));
    this.name = 'advocacyAddressWrongStateError';
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}

export class EmailTakenError extends ClientError {
  constructor() {
    super(getClientError('emailTaken'));
    this.name = 'emailTakenError';
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}

export class StripeValidationError extends ClientError {
  constructor(message: string) {
    super(message);
    this.name = 'StripeValidationError';
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}

export class InvalidZipCodeError extends ClientError {
  constructor() {
    super(getClientError('invalidZipCode'));
    this.name = 'InvalidZipCodeError';
    Object.setPrototypeOf(this, ClientError.prototype);
  }
}
