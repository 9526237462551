import gql from 'graphql-tag';

export const UPDATE_PREFERENCE_MUTATION = gql`
  mutation UpdatePreference($id: Int!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      id
      languageShortCode
    }
  }
`;
