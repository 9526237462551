import gql from 'graphql-tag';

export const GET_REDIRECT_PLAN_INFO = gql`
  query getRedirectPlanInfo($id: Int!) {
    user(id: $id) {
      id
      accounts {
        id
        customPlans {
          id
        }
      }
    }
  }
`;

export const getRedirectPlanInfoMock = {
  user: {
    id: 1,
    accounts: [
      {
        id: 2,
        customPlans: [
          {
            id: 3,
          },
        ],
      },
    ],
  },
};

export const getRedirectPlanInfoMockMutiple = {
  user: {
    id: 1,
    accounts: [
      {
        id: 2,
        customPlans: [
          {
            id: 3,
          },
          {
            id: 4,
          },
        ],
      },
    ],
  },
};
