export const modules = {
  sources: {
    expandButtonText: 'View more',
  },
  usageSummary: {
    ariaTitle: 'Your energy between %{range}',
    description: {
      usage:
        'You used %{kwh} kWh of renewable electricity from <span class="notranslate">%{windProjectName}</span> %{projectType} farm and reduced the greenhouse gases emitted to power your home.',
      average:
        'Your electricity is estimated to be %{kwh} kWh this month. All of that will come from %{windProjectName}, which doesn’t emit any carbon dioxide to generate energy.',
    },
    banner: '%{totalCarbon} lbs of CO2 avoided with %{kwh} clean kWh',
    aria: {
      buttons: {
        previous: 'Previous month',
        next: 'Next month',
      },
      links: {
        toImpact: 'Link to environmental impact page',
      },
    },
  },
  usageGraph: {
    title: 'Renewable energy use',
    description:
      'From <b>%{startDate}</b> to <b>%{endDate}</b> we estimated your electricity use by averaging your neighbors. Sync your utility account to unlock exact impact.',
    descriptionUnavailable:
      'From <b>%{startDate}</b> to <b>%{endDate}</b> we estimated your electricity use by averaging your neighbors.',
    descriptionConnected:
      'From <b>%{startDate}</b> to <b>%{endDate}</b> you used %{percentChange} %{percentChangeAdj} electricity from our wind and solar farms.',
    descriptionConnectedFirstMonth:
      'We’re starting to gather data to estimate trends in your electricity usage.',
  },
  windProject: {
    title: 'Energy source ',
    alt: '%{projectType} farm',
    description:
      'We sourced %{kwh} kWh of clean and renewable energy from <span class="notranslate">%{name}</span> in <span class="notranslate">%{location}, %{state}</span> for <b>%{startDate}</b> to <b>%{endDate}</b>.',
  },
  recs: {
    title: 'Your renewable energy certificates',
    description:
      'RECs are how we track and measure renewable energy in the US power grid.',
    kwhText: 'RECs retired from %{kwh} clean kWh',
  },
  lineItems: {
    total: 'Total',
    links: {
      utilityCharge: 'View utility charge details',
      vnmCharge: 'View Community Solar charge details',
    },
  },
  energyAnalysis: {
    title: 'Energy analysis',
    description: {
      text: 'Normally, %{fossilAndNuclearUSPercent} of the electricity in the US comes from burning fossil fuels or radioactive decay. However, your %{cleanKwh} kWh from <b>%{startDate}</b> to <b>%{endDate}</b> came from %{weatherAdjective} days in %{featuredWindProjectState}.',
      weather: {
        solar: 'sunny',
        wind: 'windy',
      },
    },
  },
  energySourceBreakdown: {
    coal: 'Coal releases more CO2 than any other fuel source and is responsible for 21% of US energy-related greenhouse gas emissions.',
    oil: 'There are thousands of oil spills in US waters every year, and we only hear about the devastatingly large ones.',
    gas: 'Natural gas is mostly Methane, a gas that’s 80 times more potent than CO2 when it comes to global warming.',
    nuclear:
      'Nuclear fission doesn’t release any greenhouse gases, but it does create a lot of radioactive waste.',
    aria: {
      buttons: {
        tab: 'Select %{source} as energy source',
      },
      links: {
        learnMore: 'Learn more about %{activeEnergySource}',
      },
    },
    links: {
      learnMore: 'Learn more',
    },
    tabButtomLabel: '%{label}',
    impact: {
      line1: '%{units} of',
      line2: '%{source} averted',
    },
  },
  contactSupport: {
    title: 'We’re here to help!',
    text: 'Email us and we’ll get back to you as soon as possible. Alternatively, you can live chat or call us between 9am - 5pm ET.',
    button: 'Contact us',
  },
  notification: {
    dismiss: 'Dismiss notification',
    vnmTerms: {
      links: {
        download: 'Download the terms',
      },
      genericTitle: 'Sign updated terms',
      title: 'Sign updated community solar terms',
      description: {
        withScroll:
          'This agreement says you will support a local solar farm and receive savings on your utility bill. You can cancel for any reason. Please scroll through and check the box to participate.',
        withoutScroll:
          'Agree to the updated terms and we’ll confirm your eligibility to participate.',
      },
      buttons: {
        submit: 'Agree',
        scroll: 'Click to scroll & agree',
      },
      checkbox: {
        label: 'VNM terms',
        description: [
          'I have read the ',
          'By proceeding, I will be submitting my e-signature.',
        ],
        linkJoiners: [', ', ' and the ', '. '],
      },
      notification: 'Terms signed!',
    },
  },
  error: {
    line1: 'Oops! Something went wrong.',
    line2: 'We’re fixing it.',
  },
  supportModal: {
    title: 'Our team is here to help',
    description:
      'Our support team is happy to answer any questions you have. We’re available Monday to Friday, 9:00am to 5:00pm ET.',
    email: {
      description: 'Email us and we’ll get back to you as soon as possible.',
      link: 'Email us',
    },
    card: {
      title: 'Other ways to get support ',
      messageDescription:
        'Leave us a note with some details. Most inquiries receive a response within 24 hours. ',
      supportDescription:
        'Checkout our support center to see if we’ve covered your question before. ',
    },
    phone: 'Call us at ',
    spacer: 'or',
    supportCenter: 'Visit Support Center',
  },
  reactivatePlan: {
    title: 'Reactivate plan?',
    description: 'Are you sure you want to reactivate %{planName}?',
    modalDescription:
      'You’ll receive emails confirming your reactivation and outlining the next steps in regards to billing and re-enrolling you onto a solar farm.',
    planFunction: 'Reactivate my plan',
    reactivationSuccess: 'Plan reactivated!',
    reactivationFailure: 'Plan reactivation failed. Try again later.',
    movers: {
      title: 'Moving home?',
      description:
        'If you’re trying to let us know that you’re moving/have moved, please fill out our movers form instead.',
      movingHome: "I'm moving home",
    },
  },
  welcome: {
    title: 'Welcome to Community Solar!',
    description:
      'Thanks for signing up! We’re excited to connect you with a local solar farm so you can start receiving your savings and making a positive impact on your local power grid.',
    nextStepsTitle: 'What happens next?',
    nextStepsItems: [
      'We will review the details you provided and search for a local solar farm for you to join.',
      'The process may take up to a year due to high demand and limited availability. We will keep you updated on the progress of your solar farm.',
      'While we connect you to a farm, you’ll have immediate access to our Arcadia Rewards program. Look out for an email with more information.',
      'Once we’ve connected you to a farm, you’ll get more details about billing and other valuable information.',
    ],
    mainCTAText: 'Go to my Solar dashboard',
  },
  sponsoredSolarWelcome: {
    title: 'Welcome to Arcadia’s Community Solar Program',
    nextStepsTitle: 'What happens next?',
    nextStepsItems: [
      'We’ll check the information you provided to ensure you’re eligible',
      'You’ll receive an email confirming your sign-up',
      'We’ll find a solar farm for you to join and email you the details once we’ve placed you on it',
      'Once the solar farm goes live, you should start to receive savings within a few months of enrollment',
      'You will be able to view information about your savings and solar farm on your dashboard',
    ],
    mainCTAText: 'Explore my dashboard',
  },
};
