import React, { useEffect } from 'react';
import { copyFor } from 'config';
import { ErrorBoundary } from 'components/error-boundary';

const getCopy = copyFor('navigation');

export const getTabTitle = title => `${getCopy('tabTitlePrefix')}${title}`;

type PageWrapperProps = {
  renderChildren: () => React.ReactNode;
  title: string;
};

export const PageWrapper = ({ title, renderChildren }: PageWrapperProps) => {
  useEffect(() => {
    document.title = getTabTitle(title);
  }, [title]);

  return <ErrorBoundary variant="secondary">{renderChildren()}</ErrorBoundary>;
};
