import React from 'react';
import { useMenuSection } from 'react-aria';
import { TreeState } from '@react-stately/tree';
import { Node } from '@react-types/shared';
import { useHistory } from 'react-router-dom';
import { Text } from '@arcadiapower/shrike';
import { routes, copyFor } from 'config';
import { AccountAvatar } from 'components/account-avatar';
import { useAccountContext } from 'contexts/account';
import { getAccountLabel } from 'domain/account-statuses';
import { useSwitchAccount } from 'hooks/use-switch-account.hook';
import { logout } from 'utils/logout';
import {
  StyledContainer,
  AccountsWrapper,
  StyledList,
  StyledSpacer,
  StyledSelectedAccountText,
} from '../menu.style';
import { MenuItem } from './menu-item.component';

const { settings } = routes();
const getCopy = copyFor('navigation.navBar');

interface Section {
  childNodes: Iterable<Node<unknown>>;
  key: string | number;
}
interface Props {
  section: Section;
  state: TreeState<unknown>;
  openSupportModal: () => void;
  onClose: () => void;
}

export const MenuSection = ({
  section,
  state,
  openSupportModal,
  onClose,
}: Props) => {
  const { selectedAccount } = useAccountContext();
  const { itemProps, groupProps } = useMenuSection({
    'aria-label': section['aria-label'],
  });

  const history = useHistory();
  const { switchAccount } = useSwitchAccount();

  const mapActions = item => {
    switch (item.key) {
      case 'settings':
        return () => history.push(settings);
      case 'logout':
        return () => {
          localStorage.removeItem('utilityModalClosed');
          logout();
        };
      case 'help':
      default:
        return () => openSupportModal();
    }
  };
  const accountLabel = getAccountLabel(selectedAccount);

  return (
    <>
      {section.key !== state.collection.getFirstKey() && <StyledSpacer />}
      <li {...itemProps}>
        {section.key === 'accountSwitcher' ? (
          <>
            <StyledContainer direction="column" padding={{ top: '8px' }}>
              <AccountAvatar accountId={selectedAccount.id} size="32px" />
              <StyledSelectedAccountText
                textStyle="heading500"
                aria-label={getCopy('aria.selectedAccount', { accountLabel })}
              >
                {getAccountLabel(selectedAccount)}
              </StyledSelectedAccountText>
            </StyledContainer>
            <StyledSpacer />
            <Text textStyle="paragraph400" color="secondary" margin="8px 16px">
              {getCopy('menu.switchAccount')}
            </Text>
            <AccountsWrapper {...groupProps}>
              {[...section.childNodes].map(node => (
                <MenuItem
                  key={node.key}
                  item={node}
                  state={state}
                  onAction={() => switchAccount(node.key.toString())}
                  onClose={onClose}
                  isAccountItem={true}
                />
              ))}
            </AccountsWrapper>
          </>
        ) : (
          <StyledList {...groupProps}>
            {[...section.childNodes].map(node => (
              <MenuItem
                key={node.key}
                item={node}
                state={state}
                onAction={mapActions(node)}
                onClose={onClose}
              />
            ))}
          </StyledList>
        )}
      </li>
    </>
  );
};
