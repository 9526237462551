import React from 'react';
import {
  DismissButton,
  FocusScope,
  useOverlay,
  useFocusVisible,
} from 'react-aria';
import { StyledPopover } from './popover.style';

interface Props {
  popoverRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  width?: string;
  height?: string;
}

export const Popover = (props: Props) => {
  const ref = React.useRef(null);
  const { popoverRef = ref, isOpen, onClose, children, width, height } = props;
  const { isFocusVisible } = useFocusVisible();

  // Handle events that should cause the popup to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: true,
    },
    popoverRef
  );

  // Add a hidden <DismissButton> component at the end of the popover
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus contain autoFocus={isFocusVisible}>
      <StyledPopover
        {...overlayProps}
        ref={popoverRef}
        style={{ width: `${width}`, height: `${height}` }}
      >
        {children}
        <DismissButton onDismiss={onClose} />
      </StyledPopover>
    </FocusScope>
  );
};
