import { routes } from 'config';

export const amendPlanToDeepLink = (
  pathname: string,
  planId: number
): string => {
  let newPath = pathname;
  const { legacyBaseRoutes, dashboardPlan } = routes({ planId });
  const legacyAdvocacyRoute = '/advocacy';
  const legacyRoute = legacyBaseRoutes.find(route => pathname.includes(route));
  const isAdvocacy = legacyRoute === legacyAdvocacyRoute;
  if (legacyRoute)
    newPath = `${dashboardPlan}${isAdvocacy ? '/solar' : ''}${pathname}`;
  return newPath;
};
