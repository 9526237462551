import React from 'react';
import { useMenuItem } from 'react-aria';
import { TreeState } from '@react-stately/tree';
import { Node } from '@react-types/shared';
import { Text } from '@arcadiapower/shrike';
import { AccountAvatar } from 'components/account-avatar';
import { StyledItem, StyledContainer, StyledText } from '../menu.style';

interface Props {
  item: Node<unknown>;
  state: TreeState<unknown>;
  onClose: () => void;
  onAction: () => void;
  isAccountItem?: boolean;
}

export const MenuItem = (props: Props) => {
  const { item, state, onClose, onAction, isAccountItem } = props;
  // Get props for the menu item element
  const ref = React.useRef(null);

  const { menuItemProps } = useMenuItem(
    { key: item.key, onAction, onClose },
    state,
    ref
  );

  return (
    <StyledItem {...menuItemProps} ref={ref}>
      {isAccountItem ? (
        <StyledContainer>
          <AccountAvatar accountId={parseInt(item.key.toString())} />
          <StyledText>{item.rendered}</StyledText>
        </StyledContainer>
      ) : (
        <Text textStyle="paragraph400">{item.rendered}</Text>
      )}
    </StyledItem>
  );
};
