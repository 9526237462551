import styled from 'styled-components';
import { Container } from 'styles';

export const ActiveBorderContainer = styled(Container).attrs({
  alignItems: 'flex-end',
  flex: true,
})<StyleProps>`
  height: 100%;
  padding-bottom: ${props => (props.active ? '0' : '2px')};

  &:hover {
    svg path {
      fill: ${props => props.theme.colors.content.primary};
    }
  }

  ${props =>
    props.active &&
    `border-bottom: 2px solid ${props.theme.colors.content.primary}`};
`;
