import gql from 'graphql-tag';

export type PlanSwitcherPlan = GetPlansQuery['account']['customPlans'][number];

export const GET_PLANS_QUERY = gql`
  query getPlans($id: Int!) {
    account(id: $id) {
      id
      customPlans {
        id
        displayName
        status
        createdAt
        utilityAccounts {
          id
          utility {
            id
            vnmEligible
            vnmProductAvailable
          }
        }
        mostRecentStatement {
          id
        }
        cleanKilowattHours {
          id
        }
      }
    }
  }
`;

export const planNickname = 'My plan';
export const secondPlanNickname = 'My other active plan';
export const deletedPlanNickname = 'My deleted plan';
const utility = {
  __typename: 'Utility',
  displayName: 'Fake Utility',
  evergreenOnly: false,
  id: 1,
  overrideEmailIsUsername: false,
  vnmProductAvailable: true,
};

export const utilityAccounts = [
  {
    __typename: 'UtilityAccounts',
    id: 5,
    utility,
  },
];

const cleanKilowattHours = [{ id: 10 }];
const mostRecentStatement = { id: 1 };

export const productSubscriptions = [{ utilityAccount: utilityAccounts[0] }];
export const noUtilityAccounts = [
  {
    __typename: 'UtilityAccounts',
    id: 5,
    utility,
  },
];

export const firstActivePlan = {
  __typename: 'Plan',
  createdAt: '2020-04-12T04:00:00.000Z',
  status: 'active',
  billable: true,
  displayName: planNickname,
  id: 20,
  utilityAccounts,
  cleanKilowattHours,
  mostRecentStatement,
};

export const secondActivePlan = {
  __typename: 'Plan',
  createdAt: '2020-04-12T04:00:00.000Z',
  status: 'active',
  billable: true,
  displayName: secondPlanNickname,
  id: 3,
  utilityAccounts,
  cleanKilowattHours,
  mostRecentStatement,
};

export const inactivePlan = {
  __typename: 'Plan',
  createdAt: '2020-04-12T04:00:00.000Z',
  status: 'inactive',
  billable: true,
  displayName: deletedPlanNickname,
  id: 4,
  utilityAccounts: noUtilityAccounts,
  cleanKilowattHours,
  mostRecentStatement,
};

export const accountWithSinglePlan = {
  account: {
    __typename: 'Account',
    customPlans: [firstActivePlan],
    id: 2,
  },
};

export const accountWithMultiplePlans = {
  account: {
    __typename: 'Account',
    customPlans: [firstActivePlan, secondActivePlan, inactivePlan],
    id: 2,
  },
};
