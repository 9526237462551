import styled from 'styled-components';
import { string } from 'prop-types';
import { MAX_PRIMARY_PAGE_WIDTH } from 'domain/style';
import { Base, baseProps } from './base.style';
import { getPropTypes } from './utils';

export const Page = styled(Base).attrs({
  padding: '24px 24px 48px',
})<StyleProps>`
  flex: 1 0 auto;
  align-self: center;
  width: 100%;
  max-width: ${props => props.maxWidth || MAX_PRIMARY_PAGE_WIDTH};

  ${props => props.theme.media.small`
    align-self: normal;
  `}
  ${props => props.theme.media.xsmall`
    align-self: normal;
  `};
`;

export const pageProps = {
  ...baseProps,
  maxWidth: string,
};

Page.propTypes = getPropTypes(pageProps);
