import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPage, getPageName, getTrimTitle } from 'domain/tracking';

export const useTrackPage = () => {
  const routerLocation = useLocation();
  const pageName = getPageName(routerLocation.pathname);
  const module = getTrimTitle();

  useEffect(() => {
    trackPage(pageName, { module });
  }, [routerLocation]);
};
