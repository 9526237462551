import React from 'react';
import { Route } from 'react-router-dom';
import { PageWrapper } from '../pages/page-wrapper';

interface Page {
  display?: boolean;
  route: string;
  title: string;
}

interface Props {
  pages: Record<string, Page>;
  pageMap: Record<string, () => JSX.Element>;
}
export const mapPages = ({ pages, pageMap }: Props) =>
  Object.keys(pages)
    .map(key => {
      const { display, route, title } = pages[key];
      if (!display) return null;
      const renderChildren = pageMap[key];

      return (
        <Route
          key={key}
          path={route}
          render={() => (
            <PageWrapper title={title} renderChildren={renderChildren} />
          )}
        />
      );
    })
    .filter(Boolean);
