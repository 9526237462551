import { isBefore } from '@arcadiapower/warbler';
import { isActive } from 'domain/account-statuses';
import { AccountContextAccount } from './account.typings';

export const sortAccounts = (
  accounts: AccountContextAccount[]
): AccountContextAccount[] => accounts.sort(compareFn);

const compareFn = (a: AccountContextAccount, b: AccountContextAccount) => {
  const activeA = isActive(a.status);
  const activeB = isActive(b.status);
  if ((activeA && activeB) || (!activeA && !activeB)) {
    return isBefore(a.createdAt, b.createdAt) ? -1 : 1;
  } else {
    return activeA ? -1 : 1;
  }
};
