import styled from 'styled-components';
import { string } from 'prop-types';
import { Base, baseProps } from './base.style';
import { breakpointStyles, getPropTypes } from './utils';

const gridStyles = props => ({
  alignItems: props.alignItems,
  gap: props.gap,
  gridColumnGap: props.columnGap || '24px',
  gridRowGap: props.rowGap,
  justifyItems: props.justifyItems,
});

export const Grid = styled(Base)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  ${gridStyles}
  ${breakpointStyles(gridStyles)};

  ${props => props.theme.media.large`
    grid-template-columns: repeat(12, 1fr);
  `}

  ${props => props.theme.media.medium`
    grid-template-columns: repeat(12, 1fr);
  `}

  ${props => props.theme.media.small`
    grid-template-columns: repeat(8, 1fr);
  `}

  ${props => props.theme.media.xsmall`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const gridProps = {
  ...baseProps,
  alignItems: string,
  columnGap: string,
  gap: string,
  justifyItems: string,
  rowGap: string,
};

Grid.propTypes = getPropTypes(gridProps);
