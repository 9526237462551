import styled from 'styled-components';
import { Button, Text } from '@arcadiapower/shrike';
import { Container } from 'styles';

export const StyledButton = styled(Button).attrs({
  backgroundless: true,
  type: 'button',
})`
  width: fit-content;
  border: none;
  padding: 8px;
  margin-left: 32px;
  margin-top: 2px;
  justify-content: flex-start;

  &:hover {
    background: ${props => props.theme.colors.background.secondary};
    cursor: pointer;
  }
`;

export const StyledText = styled(Text)`
  text-align: left;
`;

export const AccountsWrapper = styled(Container)`
  max-height: 200px;
  overflow-y: auto;
`;
