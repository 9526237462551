import React, { useRef } from 'react';
import { useListBox } from 'react-aria';
import { AriaListBoxOptions } from '@react-aria/listbox';
import { Node } from '@react-types/shared';
import { ListState } from 'react-stately';
import { Option } from './option.component';
import { List } from '../dropdown.style';

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
  optionClassName?: string;
}

export const ListBox = (props: ListBoxProps) => {
  const ref = useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state, optionClassName } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <List {...listBoxProps} ref={listBoxRef}>
      {Array.from(state.collection).map((item: Node<unknown>) => (
        <Option
          className={optionClassName}
          key={item.key}
          item={item}
          state={state}
        />
      ))}
    </List>
  );
};
