import { ELEMENT_TYPES, LOCATIONS, ElementMap } from './shared';
// The combination of elementName, elementType, location, module, and pageName
// must be unique for each viewable element. Module and pageName get added in trackEvent

export const viewableElementMap: ElementMap = {
  paymentInformation: {
    addPaymentModal: {
      addingScreen: {
        elementName: 'Stripe Details Step',
        elementType: ELEMENT_TYPES.MODAL_FLOW,
        location: LOCATIONS.PAYMENT_INFORMATION,
      },
      settingScreen: {
        elementName: 'Payment Preferences Step',
        elementType: ELEMENT_TYPES.MODAL_FLOW,
        location: LOCATIONS.PAYMENT_INFORMATION,
      },
      confirmationScreen: {
        elementName: 'Confirmation Step',
        elementType: ELEMENT_TYPES.MODAL_FLOW,
        location: LOCATIONS.PAYMENT_INFORMATION,
      },
    },
  },
};
