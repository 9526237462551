import { css } from 'styled-components';
import { shape } from 'prop-types';
import { Location } from 'history';
import { routes as getRoutes } from 'config';

export const getPropTypes = propTypes => ({
  ...propTypes,
  large: shape(propTypes),
  medium: shape(propTypes),
  small: shape(propTypes),
  xlarge: shape(propTypes),
  xsmall: shape(propTypes),
});

// New style util fns

const stringOrNumber = ['string', 'number'];

export const margin = props => ({
  margin: stringOrNumber.includes(typeof props.margin) && props.margin,
  marginBottom: props.margin?.bottom,
  marginLeft: props.margin?.left,
  marginRight: props.margin?.right,
  marginTop: props.margin?.top,
});

export const padding = props => ({
  padding: stringOrNumber.includes(typeof props.padding) && props.padding,
  paddingBottom: props.padding?.bottom,
  paddingLeft: props.padding?.left,
  paddingRight: props.padding?.right,
  paddingTop: props.padding?.top,
});

export const border = props => {
  const withBorder = `1px solid ${props.theme.colors.background.tertiary}`;
  const withoutBorder = 'none';

  if (typeof props.border === 'boolean') {
    return { border: props.border ? withBorder : withoutBorder };
  } else if (typeof props.border === 'object') {
    const getBorder = key => {
      if (typeof props.border[key] === 'boolean') {
        return props.border[key] ? withBorder : withoutBorder;
      }
    };
    return {
      borderBottom: getBorder('bottom'),
      borderLeft: getBorder('left'),
      borderRight: getBorder('right'),
      borderTop: getBorder('top'),
    };
  } else {
    return {};
  }
};

const addStyle = (props, size) => ({ ...props[size], theme: props.theme });

export const breakpointStyles = styles => css`
  ${props => props.theme.media.large`
    ${styles(addStyle(props, 'large'))}
  `}

  ${props => props.theme.media.medium`
    ${styles(addStyle(props, 'medium'))}
  `}

  ${props => props.theme.media.small`
    ${styles(addStyle(props, 'small'))}
  `}

  ${props => props.theme.media.xsmall`
    ${styles(addStyle(props, 'xsmall'))}
  `}
`;

const routes = getRoutes();

export const shouldUsePrimaryBackgroundColor = (
  location: Location<unknown>,
  statementRedesignFeatureFlag?: boolean
): boolean => {
  const { pathname } = location;
  // Notifications pages and new payment method page should have secondary background color
  const isNotificationRoute = pathname.includes(routes.notifications);
  const isPaymentMethodsPage =
    pathname === routes.paymentSettings || pathname === routes.paymentWallet;
  // Statement redesign page should have secondary background color
  const isStatementPage =
    /\/statements\/[0-9]+(?:\/|\?[^/]*)?$/.test(pathname) &&
    statementRedesignFeatureFlag;
  if (isNotificationRoute || isPaymentMethodsPage || isStatementPage)
    return false;

  // Otherwise "main" pages have secondary background color and child pages have primary
  const splitPathname = location.pathname.split('/');
  const isDashboardRoute = location.pathname.includes(routes.dashboard);
  if (!splitPathname[splitPathname.length - 1]) splitPathname.pop();
  return isDashboardRoute ? splitPathname.length > 4 : splitPathname.length > 2;
};
