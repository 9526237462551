import styled, { css } from 'styled-components';
import {
  Button as ShrikeButton,
  Text as ShrikeText,
} from '@arcadiapower/shrike';
import { zIndexDropdownPopover } from 'domain/style';

export const ListItem = styled.li<{ isFocused: boolean }>`
  padding: 8px 24px 8px 32px;
  cursor: pointer;
  border: 2px solid transparent;

  ${props =>
    props.isFocused &&
    css`
      outline: none;
      background: ${props => props.theme.colors.background.secondary};
      border: 2px solid ${props => props.theme.colors.background.controls};
    `}
`;

export const List = styled.ul`
  max-height: 300px;
  overflow: auto;
  margin: 8px 0;
  width: 100%;
`;

export const Wrapper = styled.div`
  max-width: 250px;
`;

export const Button = styled(ShrikeButton).attrs({
  backgroundless: true,
  color: 'primary',
  size: 'small',
})`
  border: none;
  padding: 7px;
  justify-content: space-between;
  max-width: 250px;

  span {
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const PopoverContainer = styled.div`
  position: absolute;
  background: ${props => props.theme.colors.background.primary};
  box-shadow: 0px 1px 4px rgba(11, 31, 28, 0.2);
  border-radius: ${props => props.theme.borderRadii.small};
  z-index: ${zIndexDropdownPopover};
`;

export const Text = styled(ShrikeText)<StyleProps>`
  font-family: ${props =>
    props.isSelected
      ? props.theme.fontFamilies.main.medium
      : props.theme.fontFamilies.main.regular};
`;
