import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Notification } from '@arcadiapower/shrike';
import { useAccountContext } from 'contexts/account';
import { AccountContextAccount } from 'contexts/account/account.typings';
import { NOTIFICATION_DISPLAY_TIME } from 'domain/ux';
import { copyFor } from 'config';
import { getRedirectRoute, getAccountLabel } from 'domain/account-statuses';

interface Result {
  switchAccount: (id: string) => void;
  selectableAccounts: AccountContextAccount[];
  showAccountSwitcher: boolean;
}

interface Props {
  onClose?: () => void;
}

const getNotificationCopy = copyFor('toastNotifications');

export const useSwitchAccount = ({ onClose }: Props = {}): Result => {
  const history = useHistory();
  const { addNotification } = useContext(Notification.Context);
  const { setSelectedAccountId, selectedAccountId, accounts } =
    useAccountContext();

  let selectableAccounts;
  if (!accounts) {
    selectableAccounts = [];
  } else {
    selectableAccounts = accounts.filter(
      account => account.id !== selectedAccountId
    );
  }

  const handleRedirect = () => {
    const redirectRoute = getRedirectRoute({
      pathname: history.location.pathname,
    });
    if (redirectRoute) {
      history.push(redirectRoute);
    }
  };

  const switchAccount = (id: string) => {
    const selectedAccount = accounts.find(
      account => account.id.toString() === id
    );

    if (selectedAccount) {
      setSelectedAccountId(Number(id));
      addNotification({
        displayTime: NOTIFICATION_DISPLAY_TIME,
        message: getNotificationCopy('accountSwitched', {
          name: getAccountLabel(selectedAccount),
        }),
        variant: 'success',
      });
      handleRedirect();
    }
    if (onClose) onClose();
  };

  return {
    selectableAccounts,
    switchAccount,
    showAccountSwitcher: !!accounts && accounts.length > 1,
  };
};
