import React from 'react';
import MX1 from '/images/mx-images/MX-image-1.png';
import MX2 from '/images/mx-images/MX-image-2.png';
import MX3 from '/images/mx-images/MX-image-3.png';
import MX4 from '/images/mx-images/MX-image-4.png';
import MX5 from '/images/mx-images/MX-image-5.png';
import {
  ContactSupportLink,
  Icon,
  Link,
  Text,
  Spacer,
  Modal,
  Card,
} from '@arcadiapower/shrike';
import { SUPPORT_PHONE_NUMBER, SUPPORT_LINK } from 'domain/ux';
import {
  zIndexSupportHeadshotsMiddleLayer,
  zIndexSupportHeadshotsTopLayer,
} from 'domain/style';
import { copyFor } from 'config';
import {
  MxHeadBubbleContainer,
  MxHeadBubble,
  SupportModal,
  SupportModalHeader,
  SupportTextWrapper,
} from './support.style';

const getCopy = copyFor('modules.supportModal');

interface Props {
  onClose: () => void;
}

export const Support = ({ onClose }: Props) => {
  const emailIcon = (
    <Icon icon="Envelope" margin={{ right: '8px' }} color="primaryInverse" />
  );
  const telephoneIcon = (
    <Icon
      icon="Phone"
      margin={{ right: '8px', bottom: '2px' }}
      color="primary"
    />
  );
  const handleZendeskDialogOpen = () => {
    // Close the main dialog when the Zendesk dialog opens, this is done so that the
    // focus can be set to the Zendesk dialog which enables editing the text in the dialog
    onClose();
  };
  return (
    <SupportModal aria-label="Contact Support">
      <SupportModalHeader title={getCopy('title')} onClose={onClose} />
      <Modal.Content>
        <Text margin={{ bottom: '24px' }}>{getCopy('description')}</Text>
        <MxHeadBubbleContainer>
          <MxHeadBubble src={MX1} />
          <MxHeadBubble zIndex={zIndexSupportHeadshotsMiddleLayer} src={MX2} />
          <MxHeadBubble zIndex={zIndexSupportHeadshotsTopLayer} src={MX3} />
          <MxHeadBubble zIndex={zIndexSupportHeadshotsMiddleLayer} src={MX4} />
          <MxHeadBubble src={MX5} />
        </MxHeadBubbleContainer>
        <Text margin={{ bottom: '24px' }}>{getCopy('email.description')}</Text>
        <Link
          fullWidth={true}
          href="mailto:support@arcadia.com"
          type="CTA"
          hideIcon={true}
          backgroundColor="primaryInverse"
        >
          {emailIcon}
          {getCopy('email.link')}
        </Link>
        <Spacer innerText={getCopy('spacer')} padding="16px 0px" />
        <Text>
          {telephoneIcon} {getCopy('phone')}
          <Link href={`tel:1-${SUPPORT_PHONE_NUMBER}`} type="inline">
            {SUPPORT_PHONE_NUMBER}
          </Link>
          .
        </Text>
        <Card margin={{ top: '32px' }}>
          <SupportTextWrapper>
            <Text textStyle="heading600">{getCopy('card.title')}</Text>
            <Text textStyle="paragraph500">
              {getCopy('card.messageDescription')}
            </Text>
            <ContactSupportLink onZendeskOpen={handleZendeskDialogOpen} />
            <Text textStyle="paragraph500">
              {getCopy('card.supportDescription')}
            </Text>
            <Link href={`${SUPPORT_LINK}`} margin={{ bottom: '16px' }}>
              {getCopy('supportCenter')}
            </Link>
          </SupportTextWrapper>
        </Card>
      </Modal.Content>
    </SupportModal>
  );
};
