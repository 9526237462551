import React from 'react';
import { range } from 'lodash-es';
import { MAX_SECONDARY_PAGE_WIDTH } from 'domain/style';
import { Container, Page, Skeleton } from 'styles';

interface Props {
  dataTestId?: string;
  numberOfSections?: number;
}

export const LoadingPage = ({ dataTestId, numberOfSections = 3 }: Props) => (
  <Page
    data-testid={dataTestId || 'loading-page'}
    surface
    maxWidth={MAX_SECONDARY_PAGE_WIDTH}
    margin="auto"
  >
    {range(numberOfSections).map(num => (
      <Container key={num} margin={{ bottom: '48px' }}>
        <Skeleton height="40px" variant="secondary" width="80%" withShimmer />
        <Skeleton
          height="72px"
          margin="16px 0"
          variant="secondary"
          width="100%"
          withShimmer
        />
        <Skeleton height="72px" variant="secondary" width="100%" withShimmer />
      </Container>
    ))}
  </Page>
);
