import { useContext } from 'react';
import { ClientError } from 'utils/errors/client-errors';
import { Account } from './account.context';

// This hook is helpful for typescript because it returns an object of type AccountContext.
// If we were to just use useContext(Account), it would return AccountContext | undefined
// and we'd have to assert that it is defined everywhere we use it
export const useAccountContext = () => {
  const context = useContext(Account);
  if (!context) {
    throw new ClientError(
      'useAccountContext must be used within an AccountProvider'
    );
  }
  return context;
};
