import { css } from 'styled-components';
import { merge } from 'lodash-es';
import { lightTheme, ShrikeTheme } from '@arcadiapower/shrike';

const arcadia: ShrikeTheme = lightTheme;

// TODO replace with new shrike breakpoints
const breakpointXSmall = 599;
const breakpointSmall = 899;
const breakpointMedium = 1199;
const breakpointLarge = 1799;

const additional = {
  colors: {
    alternative1: '#36D098',
    alternative2: '#1DA590',
    alternative3: '#8AA1B8',
    errorVariant: '#FCECE7',
    solarGraphBars: '#FDC02F',
    blueBackground: '#E3EEFF',
  },
  opacities: {
    uncharacteristicallyLow: '10%',
  },
};

export const theme: ShrikeTheme & {
  media: {
    breakpoints: {
      large: any;
      medium: any;
      small: any;
      xsmall: any;
    };
  };
} = merge(arcadia, {
  ...additional,
  media: {
    breakpoints: {
      large: breakpointLarge,
      medium: breakpointMedium,
      small: breakpointSmall,
      xsmall: breakpointXSmall,
    },
    // css.call(undefined, ...args) is a Typescript hack to let us call
    // css() with possibly undefined args
    large: (...args) => css`
      @media (max-width: ${breakpointLarge}px) {
        ${''}
        ${css.call(undefined, ...args)}
      }
    `,
    medium: (...args) => css`
      @media (max-width: ${breakpointMedium}px) {
        ${''}
        ${css.call(undefined, ...args)}
      }
    `,
    small: (...args) => css`
      @media (max-width: ${breakpointSmall}px) {
        ${''}
        ${css.call(undefined, ...args)}
      }
    `,
    xlarge: (...args) => css`
      @media (min-width: ${breakpointLarge + 1}px) {
        ${''}
        ${css.call(undefined, ...args)}
      }
    `,
    xsmall: (...args) => css`
      @media (max-width: ${breakpointXSmall}px) {
        ${''}
        ${css.call(undefined, ...args)}
      }
    `,
  },
});
