import React from 'react';
import { useTreeState } from 'react-stately';
import { CollectionChildren } from '@react-types/shared';
import { useMenu } from 'react-aria';
import { MenuSection } from './menu-section.component';
import { StyledList } from '../menu.style';

interface Props {
  openSupportModal: () => void;
  // eslint-disable-next-line
  children: CollectionChildren<object>;
  onClose: () => void;
}

export const MenuContent = (props: Props) => {
  // Create menu state based on the incoming props
  const state = useTreeState(props);

  // Get props for the menu element
  const ref = React.useRef(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <StyledList {...menuProps} ref={ref}>
      {[...state.collection].map(item => (
        <MenuSection key={item.key} section={item} state={state} {...props} />
      ))}
    </StyledList>
  );
};
